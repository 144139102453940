import { acceptHMRUpdate, defineStore } from 'pinia'

import type { UserKind } from '~/types/UserKind'

export interface MiscBandSignupWidgetContext {
  entityId: number
  userKind: UserKind
}

const state = () => ({
  isTransitioningToSignup: false,
  widgetContext: null as null | MiscBandSignupWidgetContext,
  soundcloudContext: {
    first_name: '',
    soundcloud_code: null as string | null,
    soundcloud_token: null as string | null,
  },
})

export type IMiscBandSignupState = ReturnType<typeof state>

export const useMiscBandSignupStore = defineStore('miscBandSignup', {
  state: (): IMiscBandSignupState => ({ ...state() }),
  actions: {
    SET_SOUNDCLOUD_SIGNUP_STATUS(
      status: IMiscBandSignupState['soundcloudContext'],
    ) {
      this.soundcloudContext = status
    },
    SET_TRANSITIONING_STATUS(isTransitioning: boolean) {
      this.isTransitioningToSignup = isTransitioning
    },
    SET_WIDGET_CONTEXT(newContext: MiscBandSignupWidgetContext | null) {
      this.widgetContext = newContext
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscBandSignupStore, import.meta.hot),
  )
}
