import { acceptHMRUpdate, defineStore } from 'pinia'

import { usePayinBillingInfosLegalDataStore } from './payinBillingInfosLegalData'

import type { LegalData } from '~/types/legalData'

const state = () => ({
  isEditing: false,
})

export type PayinBillingInfosState = ReturnType<typeof state>

export const usePayinBillingInfosStore = defineStore(
  'payinBillingInfos',
  () => {
    const { $pinia } = useNuxtApp()
    const payinBillingInfosLegalDataStore =
      usePayinBillingInfosLegalDataStore($pinia)

    const isEditing = ref(false)

    // getters
    const IS_COMPLETE = computed(() => {
      return payinBillingInfosLegalDataStore.id > 0
    })

    // actions

    function SET_IS_EDITING(payload: boolean) {
      isEditing.value = payload
    }

    function RESET() {
      isEditing.value = false
      return payinBillingInfosLegalDataStore.RESET()
    }

    function FETCH() {
      return payinBillingInfosLegalDataStore.FETCH()
    }

    function SET(billingInfos: { legal_data: LegalData }) {
      payinBillingInfosLegalDataStore.SET(billingInfos.legal_data)
    }

    function UPDATE_ON_SERVER(billingInfos: {
      legal_data: LegalData
    }): Promise<{ legal_data: LegalData }> {
      return new Promise((resolve, reject) => {
        Promise.all([
          payinBillingInfosLegalDataStore.UPDATE_ON_SERVER(
            billingInfos.legal_data,
          ) as Promise<LegalData>,
        ])
          .then(([legalData]) => {
            resolve({ legal_data: legalData })
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    return {
      // state
      isEditing,

      // getters
      IS_COMPLETE,

      // actions
      SET_IS_EDITING,
      RESET,
      FETCH,
      SET,
      UPDATE_ON_SERVER,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinBillingInfosStore, import.meta.hot),
  )
}
