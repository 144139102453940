import { storeToRefs } from 'pinia'

import { useUserStore } from '~/stores/user'
import { useUserBandSetStore } from '~/stores/userBandSet'

export function useIsUserOwnerOfBand(bandSlug: MaybeRefOrGetter<string>) {
  const { $pinia } = useNuxtApp()
  const { IS_BAND } = storeToRefs(useUserStore($pinia))
  const { list: BAND_LIST } = storeToRefs(useUserBandSetStore($pinia))

  const userIsOwner = computed<boolean>(() => {
    if (!IS_BAND.value) return false

    return BAND_LIST.value.some((bandData) => {
      return bandData.slug === toValue(bandSlug)
    })
  })

  return {
    userIsOwner,
  }
}
