import { useBandSignupStore } from '~/stores/bandSignup'
import { useUserStore } from '~/stores/user'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserBandSetStore } from '~/stores/userBandSet'

import { BandSignupStep } from '~/enums/BandSignupStep'

import type { Band } from '~/types/band'
import type { RouteLocationNormalized } from 'vue-router'

export function useSignupEvaluation() {
  const { $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const userAgencyStore = useUserAgencyStore($pinia)
  const userBandSetStore = useUserBandSetStore($pinia)
  const bandSignupStore = useBandSignupStore($pinia)

  const bandSignupPastLinkCookie = useCookie(
    `bandSignupPastLink${userStore.id}`,
  )
  const bandSignupPastTagsCookie = useCookie(
    `bandSignupPastTags${userStore.id}`,
  )
  const bandSignupPastPictureUploadCookie = useCookie(
    `bandSignupPastPictureUpload${userStore.id}`,
  )

  function evaluateSignupStep(route: RouteLocationNormalized) {
    if (userStore.id === 0) {
      bandSignupStore.SET_STEP(0)
      bandSignupStore.DISPLAY()
      throw new Error('Need redirection')
    }

    const FIRST_BAND: Band | undefined = userBandSetStore.list[0]
    let step = BandSignupStep.SelectAgencyKind

    /**
     * Initial step evaluation.
     */
    if (userAgencyStore.kind !== '' && !userAgencyStore.id)
      step = BandSignupStep.CreateAgency
    else if (
      userAgencyStore.id === 0 ||
      ['', 'No'].includes(userAgencyStore.kind)
    )
      step = BandSignupStep.SelectAgencyKind
    else if (!FIRST_BAND) step = BandSignupStep.CreateBand
    else if (
      !FIRST_BAND.link.length &&
      !(Object.values(FIRST_BAND.media_links ?? {}) as string[]).some(
        (e) => e.length,
      ) &&
      !bandSignupPastLinkCookie.value
    )
      step = BandSignupStep.SetSocialMedia
    else if (
      !FIRST_BAND.tags.identity.subgenre &&
      !FIRST_BAND.tags.identity.mood &&
      !FIRST_BAND.tags.identity.artist_kind &&
      !bandSignupPastTagsCookie.value
    )
      step = BandSignupStep.SelectSubgenres
    else if (
      !FIRST_BAND.has_profile_picture &&
      !bandSignupPastPictureUploadCookie.value
    )
      step = BandSignupStep.UploadPicture
    else step = BandSignupStep.SelectTrackReleaseStatus

    /**
     * Store sync-up.
     */
    if (!route.query.step || Number(route.query.step) > step)
      bandSignupStore.SET_STEP(step)
    else if (!isNaN(Number(route.query.step)))
      bandSignupStore.SET_STEP(Number(route.query.step))
  }

  return {
    evaluateSignupStep,
  }
}
