import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useMiscCurrencyStore } from '~/stores/miscCurrency'

import { provideFetchCurrencyExchangeRates } from '~/api-core/Currency/FetchExchangeRates'

import type { Currency } from '~/types/currency'

export function useCurrencySetter() {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const miscCurrencyStore = useMiscCurrencyStore($pinia)
  const backupCurrencyCookie = useCookie('currencyBackup')

  async function initCurrency() {
    const fetchCurrencyExchangeRates =
      provideFetchCurrencyExchangeRates(coreFetch)
    const { base, rates } = await fetchCurrencyExchangeRates()

    miscCurrencyStore.SET_TARGET_CONVERSION_BASE(base)
    miscCurrencyStore.SET_LOOK_UP_TABLE(rates)
    if (backupCurrencyCookie.value) setTargetCurrencyFromBackup()

    return rates
  }

  function setTargetCurrency(newTargetCurrency: Currency) {
    if (newTargetCurrency !== miscCurrencyStore.targetCurrency)
      backupCurrencyCookie.value = newTargetCurrency
    miscCurrencyStore.SET_TARGET_CURRENCY(newTargetCurrency)
  }

  function setTargetCurrencyFromBackup() {
    if (
      (backupCurrencyCookie.value &&
        miscCurrencyStore.supportedConvertedCurrencies.includes(
          backupCurrencyCookie.value as any,
        )) ||
      backupCurrencyCookie?.value === 'EUR'
    )
      setTargetCurrency(backupCurrencyCookie.value as Currency)
    else backupCurrencyCookie.value = null
  }

  return {
    initCurrency,
    setTargetCurrency,
    backupCurrencyCookie,
  } as const
}
