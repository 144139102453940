import type { Store } from 'pinia'

export function resetStoreToInitialState(
  this: Store,
  initialState: any,
  ignoredKeys?: string[],
) {
  if (!ignoredKeys || !ignoredKeys.length) {
    this.$reset()
    return
  }

  for (const key in initialState) {
    if (!ignoredKeys.includes(key))
      // @ts-expect-error
      this[key] = initialState[key]
  }
}
