import { acceptHMRUpdate, defineStore } from 'pinia'

import { useTagStore } from './tag'
import { useUserFavoritesStore } from './userFavorites'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import type { AgencyKind } from '~/types/agencyKind'
import type { TagTarget } from '~/types/tagTarget'
import type { TagTypeKey } from '~/types/tagTypeKey'

type GuarantiedTagTypeKey = Extract<TagTypeKey, 'agency_jobs' | 'country'>

const createTagState = () =>
  ({
    // tags: {
    identity: {
      country: [],
      agency_jobs: [],
    },
  }) as Partial<
    Record<
      TagTarget,
      Partial<
        Record<TagTypeKey, number[]> & Record<GuarantiedTagTypeKey, number[]>
      >
    >
  >
// }

const initialState = () => ({
  entity: '',
  id: 0,
  kind: '' as AgencyKind | '',
  last_excel_export_date: false as boolean | string,
  latest_campaign_date: null as null | string,
  promo: '',
  hidden_influencers: [] as number[],
  tags: createTagState(),
})

export type UserAgencyState = Pick<
  ReturnType<typeof useUserAgencyStore>,
  | 'entity'
  | 'id'
  | 'kind'
  | 'last_excel_export_date'
  | 'latest_campaign_date'
  | 'promo'
  | 'hidden_influencers'
  | 'tags'
>

export const useUserAgencyStore = defineStore('userAgency', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const tagStore = useTagStore($pinia)
  const userFavoritesStore = useUserFavoritesStore($pinia)

  const entity = ref('')
  const id = ref(0)
  const kind = ref<AgencyKind | ''>('')
  const last_excel_export_date = ref<boolean | string>(false)
  const latest_campaign_date = ref<null | string>(null)
  const promo = ref('')
  const hidden_influencers = ref<number[]>([])
  const tags = ref<ReturnType<typeof createTagState>>(createTagState())

  const HAS_COUNTRIES = computed<boolean>(() => {
    const countries = tags.value.identity?.country
    return Boolean(countries && countries.length)
  })

  const US_IS_ONLY_COUNTRY_FOR_AGENCY = computed<boolean>(() => {
    const countries = tags.value.identity?.country || []

    if (countries.length && countries.length === 1) {
      const tagName = tagStore.GET_TAG_FROM_ID(countries[0])?.name
      return Boolean(tagName && tagName === 'united-states')
    }

    return false
  })

  function getStateRef(key: keyof UserAgencyState) {
    switch (key) {
      case 'entity':
        return entity
      case 'id':
        return id
      case 'kind':
        return kind
      case 'last_excel_export_date':
        return last_excel_export_date
      case 'latest_campaign_date':
        return latest_campaign_date
      case 'promo':
        return promo
      case 'hidden_influencers':
        return hidden_influencers
      case 'tags':
        return tags
    }
  }

  function SET(data: Partial<UserAgencyState>) {
    Object.keys(data).forEach((patchKey) => {
      const ref = getStateRef(patchKey as keyof UserAgencyState)
      if (ref && patchKey !== 'tags')
        // @ts-expect-error trust
        ref.value = data[patchKey as keyof UserAgencyState]
    })

    /**
     * If incoming data has tag data, update them.
     * This is necessary because backend sometime can return empty targetObjects.
     */
    if (data.tags) {
      Object.entries(data.tags).forEach(([targetKey, targetObject]) => {
        if (targetObject) {
          Object.entries(targetObject).forEach(([typeKey, typeObject]) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore typed checked beforehand
            tags.value[targetKey][typeKey] = typeObject
          })
        }
      })
    }
  }

  function UPDATE_TAG_FROM_TARGET_AND_TYPE({
    tagTarget,
    tagType,
    patch,
  }: {
    tagTarget: TagTarget
    tagType: TagTypeKey
    patch: number[]
  }) {
    const toUpdate = tags.value[tagTarget]
    if (toUpdate) toUpdate[tagType] = patch
  }

  function LOGOUT() {
    Object.keys(initialState()).forEach((key) => {
      const ref = getStateRef(key as keyof UserAgencyState)
      if (ref) ref.value = initialState()[key as keyof UserAgencyState]
    })
  }

  function UPDATE_HIDDEN_INFLUENCERS(hiddenInfluencers: number[]) {
    return coreFetch
      .$patch<UserAgencyState>(`/agency/agency/${id.value}/`, {
        hidden_influencers: hiddenInfluencers,
      })
      .then((response) => {
        SET(response)
        userFavoritesStore.FETCH_BUCKETS({ fetchRecommended: true })
      })
      .catch(/** mute error */)
  }

  return {
    // state
    entity,
    id,
    kind,
    last_excel_export_date,
    latest_campaign_date,
    promo,
    hidden_influencers,
    tags,

    // getters
    HAS_COUNTRIES,
    US_IS_ONLY_COUNTRY_FOR_AGENCY,

    // actions
    SET,
    UPDATE_TAG_FROM_TARGET_AND_TYPE,
    LOGOUT,
    UPDATE_HIDDEN_INFLUENCERS,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserAgencyStore, import.meta.hot))
