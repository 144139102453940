import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftTrackStore } from './draftTrack'
import { useInfluencersStore } from './influencers'

import { provideDeduplicateTrack } from '~/api-core/Submissions/InfluencerDashboard'

export type InfluencersDuplicatesState = Pick<
  ReturnType<typeof useInfluencersDuplicatesStore>,
  'list' | 'trackId'
>

export const useInfluencersDuplicatesStore = defineStore(
  'influencersDuplicates',
  () => {
    const { $pinia } = useNuxtApp()
    const { coreFetch } = useProvideCoreFetch()
    const influencersStore = useInfluencersStore($pinia)
    const draftTrackStore = useDraftTrackStore($pinia)

    const list = ref<number[]>([])
    const trackId = ref<number>(0)

    function SET_LIST(newList: number[]) {
      list.value = newList
    }

    function SET_TRACK_ID(newTrackId: number) {
      trackId.value = newTrackId
    }

    function RESET() {
      list.value = []
      trackId.value = 0
    }

    async function FETCH_DUPLICATE_SUBMISSIONS() {
      const influencerIds = influencersStore.list.map(({ id }) => id)
      const trackId = draftTrackStore.id

      const deduplicateTrack = provideDeduplicateTrack(coreFetch)
      const response = await deduplicateTrack(trackId, influencerIds).catch(
        () => ({ duplicates: [] }),
      )
      const duplicateIds = response?.duplicates.map(({ id }) => id)

      SET_LIST(duplicateIds)
      return duplicateIds
    }

    return {
      // state
      list,
      trackId,

      // actions
      SET_LIST,
      SET_TRACK_ID,
      RESET,
      FETCH_DUPLICATE_SUBMISSIONS,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencersDuplicatesStore, import.meta.hot),
  )
}
