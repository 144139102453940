import configToQs from '~/helpers/configToQs'

import type { CoreFetch } from '~/types/CoreFetch'
import type { APIDraftResponse } from '~/types/draft'
import type { StatsV3Influencer } from '~/types/influencer'
import type InfluencerRecommendation from '~/types/influencerRecommendation'

export function provideFetchFirstCampaign(coreFetch: CoreFetch) {
  return function (bandId: number) {
    return coreFetch.$get<StatsV3Influencer[]>(
      `/recommendation/first_campaign/band/${bandId.toString()}/`,
    )
  }
}

export function provideFetchNewInfluencers(coreFetch: CoreFetch) {
  return function (bandId: number) {
    return coreFetch.$get<StatsV3Influencer[]>(
      `/recommendation/new_influencers/band/${bandId.toString()}/`,
    )
  }
}

export function provideFetchDraftRecommendedInfluencers(coreFetch: CoreFetch) {
  const timeout =
    (import.meta.env.DEV ?? process.env.ENV_NAME === 'dev') ? 9000 : 5000

  return function (draftId: APIDraftResponse['id'], nInfluencers = 15) {
    return coreFetch
      .$get<StatsV3Influencer['id'][]>(
        `/recommendation/influencer_similarity/upsell/${draftId}/`,
        {
          query: { n_influencers: nInfluencers },
          timeout,
        },
      )
      .catch(() => [] as StatsV3Influencer['id'][])
  }
}

export function provideFetchRecommendation(coreFetch: CoreFetch) {
  return function (
    bandId: number,
    draftId: number | undefined,
    timeout = 2500,
  ) {
    return coreFetch.$get<{
      top_influencer_count: number
      recommendations: InfluencerRecommendation[]
    }>(
      `/recommendation/recommendation/?${configToQs({
        band_id: bandId,
        draft_id: draftId,
      })}`,
      { timeout },
    )
  }
}
