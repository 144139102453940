import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftStore } from './draft'
import { useUserStore } from './user'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { computePercentPromotionOnCost } from '~/helpers/promos'

import type { ProgressivePromo } from '~/types/product'

const initialState = () => ({
  list: [] as ProgressivePromo[],
})

export type ProgressivePromosState = ReturnType<typeof initialState>

export const useMiscDraftProgressivePromosStore = defineStore(
  'miscDraftProgressivePromos',
  () => {
    const { coreFetch } = useProvideCoreFetch()
    const { $pinia } = useNuxtApp()
    const userStore = useUserStore($pinia)

    const list = ref<ProgressivePromo[]>([])

    function SET_LIST(payload: ProgressivePromo[]) {
      list.value = payload
    }

    function FETCH() {
      if (list.value.length || !userStore.IS_BAND) return Promise.resolve()

      return coreFetch
        .$get<ProgressivePromo[]>('/wallet/progressive-promos/')
        .then((response) => {
          SET_LIST(response)
        })
    }

    function PROGRESSIVE_PROMO_FROM_INF_COUNT(
      influencerCount: number,
    ): ProgressivePromo | null {
      const promos = list.value.length ? list.value : null

      if (!promos) return null

      const eligiblePromos = promos.filter(
        (promo) =>
          influencerCount >= promo.progressive_parameters.min_influencers,
      )

      return (
        eligiblePromos.sort(
          (a, b) => b.actions.campaign.value - a.actions.campaign.value,
        )?.[0] ?? null
      )
    }

    const IS_ENABLED = computed(() => {
      return list.value.length > 0
    })

    return {
      // state
      list,

      // actions
      SET_LIST,
      FETCH,

      // getters
      PROGRESSIVE_PROMO_FROM_INF_COUNT,
      IS_ENABLED,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscDraftProgressivePromosStore, import.meta.hot),
  )
}
