import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import {
  createGrooverBucket,
  grooverBucketIsEqualToIdentification,
} from '~/helpers/favorites/grooverBucket'

import type { IGrooverBucket } from '~/helpers/favorites/grooverBucket'
import type GrooverBucket from '~/helpers/favorites/grooverBucket'
import type { StatsV3Influencer } from '~/types/influencer'

const state = () => ({
  buckets: [] as GrooverBucket[],
})

export type UserFavoritesRecommendedState = ReturnType<typeof state>

export const useUserFavoritesRecommendedStore = defineStore(
  'userFavoritesRecommended',
  () => {
    const { coreFetch } = useProvideCoreFetch()
    const { $pinia } = useNuxtApp()
    const userBandStore = useUserBandStore($pinia)
    const influencersStore = useInfluencersStore($pinia)

    // state
    const buckets = ref<GrooverBucket[]>([])

    // getters
    function GET_INFLUENCERS_FROM_BUCKET(
      bucket: GrooverBucket,
    ): StatsV3Influencer[] {
      return influencersStore.GET_BY_IDS_SORTED(bucket.influencerIds)
    }

    const GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS = computed<number[]>(() => {
      return [
        ...new Set(
          buckets.value.reduce((accumulator, bucket) => {
            accumulator = [...accumulator, ...bucket.influencerIds]
            return accumulator
          }, [] as number[]),
        ),
      ]
    })

    function GET_BUCKET_FROM_IDENTIFICATION_VALUE(
      identity: GrooverBucket['list_name'],
    ) {
      return buckets.value.find((grooverBucket) =>
        grooverBucketIsEqualToIdentification(grooverBucket, identity),
      )
    }

    // actions
    function SET_BUCKETS(bucketArray: GrooverBucket[]) {
      buckets.value = bucketArray
    }

    async function FETCH_BUCKETS() {
      if (!userBandStore.id || buckets.value.length) return

      try {
        // timteout for just in case reco service isn't responding and we need to free up concurrenty requests
        const timeout = 10000
        const buckets = await coreFetch.$get<IGrooverBucket[]>(
          `/recommendation/groover_lists/?band_id=${userBandStore.id}`,
          { timeout },
        )

        SET_BUCKETS(buckets.map((bucket) => createGrooverBucket(bucket)))
      } catch (_) {}
    }

    function FETCH_INFLUENCERS(): Promise<void> {
      return new Promise((resolve) => {
        if (
          buckets.value.length &&
          GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS.value.length
        ) {
          influencersStore
            .FETCH_SET(GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS.value)
            .finally(() => {
              resolve()
            })
        } else {
          resolve()
        }
      })
    }

    return {
      // state
      buckets,

      // getters
      GET_INFLUENCERS_FROM_BUCKET,
      GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS,
      GET_BUCKET_FROM_IDENTIFICATION_VALUE,

      // actions
      SET_BUCKETS,
      FETCH_BUCKETS,
      FETCH_INFLUENCERS,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserFavoritesRecommendedStore, import.meta.hot),
  )
}
