import payload_plugin_0BAb1iiZfe from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.4_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_HcZG6rm02a from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KNKiS1U4Hi from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tUHo7PfxF4 from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_xQoPgc1T0I from "/root/front/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.27.4_vite@6.0.0_@types+node@20.17.8_jiti@2.4._rt6h5fsbgtgwm7k53fr7vdu45i/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_73MS6u3kMb from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mIbCDZEJlm from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_dT7CAxYpm1 from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_aN2SnGANjm from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.4_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_zO9ecyLUGx from "/root/front/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.8_eslint@8.57.1_ioredis@5.4.1_magicast_xnnbjv6amg5vijlq7v5f2o46za/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_Hm8Xmq2tqu from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hEZWtRQuEs from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import globalNuxtLink_8h7uhS7dNV from "/root/front/plugins/globalNuxtLink.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
import preview_6oGpsaen2C from "/root/front/plugins/preview.ts";
import customerIO_cJd4DriT22 from "/root/front/plugins/customerIO.ts";
export default [
  payload_plugin_0BAb1iiZfe,
  payloadToJson_f85A5nYSY0,
  revive_payload_client_HcZG6rm02a,
  unhead_KNKiS1U4Hi,
  router_tUHo7PfxF4,
  _0_siteConfig_xQoPgc1T0I,
  payload_client_73MS6u3kMb,
  navigation_repaint_client_mIbCDZEJlm,
  chunk_reload_client_dT7CAxYpm1,
  plugin_vue3_aN2SnGANjm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zO9ecyLUGx,
  switch_locale_path_ssr_Hm8Xmq2tqu,
  i18n_hEZWtRQuEs,
  plugin_wy0B721ODc,
  polyfills_client_xo6BY28vLt,
  globalNuxtLink_8h7uhS7dNV,
  sentry_3AyO8nEfhE,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt,
  preview_6oGpsaen2C,
  customerIO_cJd4DriT22
]