import type { CoreFetch } from '~/types/CoreFetch'

export interface DraftAddInfluencerAPIResponse {
  failed_influencers: number[]
}

export function provideAddInfluencersToDraft(coreFetch: CoreFetch) {
  return (draftId: number, influencerIds: number[]) =>
    coreFetch.$post<DraftAddInfluencerAPIResponse>(
      `/submission/draft/${draftId}/influencers/add/`,
      {
        influencers: influencerIds,
      },
    )
}

export function provideRemoveInfluencersFromDraft(coreFetch: CoreFetch) {
  return (draftId: number, influencerIds: number[]) =>
    coreFetch.$post<DraftAddInfluencerAPIResponse>(
      `/submission/draft/${draftId}/influencers/remove/`,
      {
        influencers: influencerIds,
      },
    )
}
