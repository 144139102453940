import type { CoreFetch } from '~/types/CoreFetch'

const SAFEGUARD_LOOP_MAX_RETRY_COUNT = 150

export const lockSafeguard = function <T>(
  coreFetch: CoreFetch,
  fetchPromiseToRetry: Promise<T>,
  data: any,
  url: string,
): Promise<T> {
  return new Promise((resolve, reject) => {
    fetchPromiseToRetry
      .then((resp: T) => {
        resolve(resp)
      })
      .catch((err: any) => {
        if (err?.response?.status === 409) {
          lockSafeguardLoop(coreFetch, data, 0, url)
            .then((resp: T) => {
              resolve(resp)
            })
            .catch((resp) => {
              reject(resp)
            })
        } else {
          reject(err)
        }
      })
  })
}

function lockSafeguardLoop<T>(
  coreFetch: CoreFetch,
  data: T,
  tries: number,
  url: string,
): Promise<T> {
  return new Promise((resolve, reject) => {
    coreFetch
      .$post(`${url}lock/`, data as Record<string, any>)
      .then((resp: T) => {
        resolve(resp)
      })
      .catch((err) => {
        if (tries < SAFEGUARD_LOOP_MAX_RETRY_COUNT) {
          window.setTimeout(() => {
            lockSafeguardLoop(coreFetch, data, tries + 1, url)
              .then(resolve)
              .catch(reject)
          }, 1000 * tries)
        } else {
          reject(err)
        }
      })
  })
}
