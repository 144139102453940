import type { CurrencyLookUpTable } from '~/stores/miscCurrency'
import type { CoreFetch } from '~/types/CoreFetch'

interface CurrencyApiResponse {
  timestamp: never
  base: 'USD'
  rates: CurrencyLookUpTable
}

export function provideFetchCurrencyExchangeRates(coreFetch: CoreFetch) {
  return function fetchCurrencyExchangeRates() {
    return coreFetch.$get<CurrencyApiResponse>('/currency/exchange-rates/')
  }
}
