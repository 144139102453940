<template>
  <div id="ignoreFontUpscale" class="ignoreFontUpscale tw-page-wrapper">
    <div
      class="tw-w-max-[1024px] tw-mx-auto tw-my-10 tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-gap-y-8 md:tw-flex-nowrap"
    >
      <div class="tw-w-64 tw-flex-shrink-0">
        <ParameterNavigation v-model="selected" :config="config" />
      </div>
      <div v-if="selected" class="tw-w-full">
        <transition name="fade" mode="out-in">
          <component :is="selected" :key="selected" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BandParameterAccountTypeSelect from '~/components/band/parameter/AccountTypeSelect.vue'
import BandParameterConnectThirdParty from '~/components/band/parameter/ConnectThirdParty.vue'
import BandParameterRequestExcelExport from '~/components/band/parameter/RequestExcelExport.vue'
import ParameterNavigation from '~/components/parameter/Navigation.vue'
import SharedParameterAccountNameEdit from '~/components/shared/parameter/AccountNameEdit.vue'
import SharedParameterAccountPasswordEdit from '~/components/shared/parameter/AccountPasswordEdit.vue'
import SharedParameterSelectAccountLang from '~/components/shared/parameter/SelectAccountLang.vue'

import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'

import type {
  ArtistNavigationComponent,
  NavigationConfig,
} from '~/types/Parameter'

const availableComponentNames = [
  'SharedParameterAccountNameEdit',
  'BandParameterAccountTypeSelect',
  'BandParameterConnectThirdParty',
  'BandParameterRequestExcelExport',
  'SharedParameterSelectAccountLang',
  'SharedParameterAccountPasswordEdit',
] as const
type ComponentName = (typeof availableComponentNames)[number]

export default defineNuxtComponent({
  components: {
    ParameterNavigation,
    SharedParameterAccountNameEdit,
    BandParameterAccountTypeSelect,
    BandParameterConnectThirdParty,
    BandParameterRequestExcelExport,
    SharedParameterSelectAccountLang,
    SharedParameterAccountPasswordEdit,
  },
  setup() {
    const { FETCH: FETCH_TAGS } = useTagStore()

    callOnce('FETCH_TAGS', FETCH_TAGS)
    definePageMeta({
      middleware: [
        'is-logged-in',
        () => {
          const { $localePath, $pinia } = useNuxtApp()
          const { IS_INF } = useUserStore($pinia)

          if (IS_INF) return navigateTo($localePath('/influencer/parameter/'))
        },
      ],
    })
  },
  data() {
    const config: NavigationConfig<ArtistNavigationComponent>[] = [
      {
        componentName: 'SharedParameterAccountNameEdit',
        i18nKey: this.$t('shared.parameter00'),
      },
      {
        componentName: 'SharedParameterAccountPasswordEdit',
        i18nKey: this.$t('shared.parameter01'),
      },
      {
        componentName: 'BandParameterConnectThirdParty',
        i18nKey: this.$t('band.parameter00'),
      },
      {
        componentName: 'BandParameterAccountTypeSelect',
        i18nKey: this.$t('band.parameter02'),
      },
      {
        componentName: 'SharedParameterSelectAccountLang',
        i18nKey: this.$t('shared.parameter02'),
      },
      {
        componentName: 'BandParameterRequestExcelExport',
        i18nKey: this.$t('band.parameter03'),
      },
    ]
    return {
      config,
      selected: 'SharedParameterAccountNameEdit' as ComponentName,
    }
  },
  created() {
    const query = this.$route.query.tab
    if (
      query &&
      !(query instanceof Array) &&
      this.stringIsValidComponent(query)
    )
      this.selected = query
  },
  methods: {
    stringIsValidComponent(input: string): input is ArtistNavigationComponent {
      return availableComponentNames.includes(
        input as ArtistNavigationComponent,
      )
    },
  },
})
</script>

<style scoped src="~/assets/helpers/redundantBlocks.css" />
