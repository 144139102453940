import { acceptHMRUpdate, defineStore } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useUserFavoritesRecommendedStore } from './userFavoritesRecommended'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import type { UserFavoritesRecommendedState } from '~/stores/userFavoritesRecommended'
import type Bucket from '~/types/bucket'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { OriginPageName } from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryBucketList'

type IUserFavoritesState = Pick<
  ReturnType<typeof useUserFavoritesStore>,
  'buckets' | 'inspectedInfluencers' | 'originPage' | 'campaignId'
>

export interface UserFavoritesState extends IUserFavoritesState {
  recommended: UserFavoritesRecommendedState
}

export const useUserFavoritesStore = defineStore('userFavorites', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const userFavoritesStore = useUserFavoritesStore($pinia)
  const influencerStore = useInfluencersStore($pinia)
  const userFavoritesRecommendedStore = useUserFavoritesRecommendedStore($pinia)

  // state
  const buckets = ref<Bucket[]>([])
  const inspectedInfluencers = ref<(Influencer | StatsV3Influencer)[] | null>(
    null,
  )
  const originPage = ref<OriginPageName | ''>('')
  const campaignId = ref<number | undefined>()

  // getters
  function GET_BUCKET_FROM_ID(id: number): Bucket | undefined {
    return buckets.value.find((elem) => {
      return elem.id === id
    })
  }

  const BUCKET_EXISTS = computed(() => {
    return function (id: number) {
      return userFavoritesStore.IDS.includes(id)
    }
  })

  const IDS = computed(() => {
    return buckets.value.map((bucket) => bucket.id)
  })

  function INF_ID_IS_IN_BUCKET(id: number) {
    // only in buckets editable by the user
    return buckets.value
      .filter((b: Bucket) => !b.is_groover_made)
      .some((bucket) => {
        return bucket.influencers.includes(id)
      })
  }

  // NOT necessary
  const ORIGIN_PAGE = computed(() => {
    return originPage.value
  })

  // NOT necessary
  const CAMPAIGN_ID = computed(() => {
    return campaignId.value
  })

  // actions
  function SET_BUCKETS(bucketArray: Bucket[]) {
    buckets.value = [...bucketArray]
  }

  function ADD_BUCKETS(bucketArray: Bucket[]) {
    buckets.value = [...buckets.value, ...bucketArray]
  }

  function ADD_BUCKET(bucket: Bucket) {
    buckets.value.push(bucket)
  }

  function REMOVE_BUCKET(bucketId: number) {
    buckets.value.splice(
      buckets.value.findIndex((elem) => {
        return elem.id === bucketId
      }),
      1,
    )
  }

  function REMOVE_BUCKETS() {
    buckets.value = []
  }

  function UPDATE_BUCKET(bucket: Bucket) {
    for (let i = buckets.value.length - 1; i >= 0; i--) {
      const elem = buckets.value[i]

      if (elem.id === bucket.id) {
        buckets.value[i] = { ...bucket }
        break
      }
    }
  }

  function ADD_INF_FROM_BUCKET({
    bucketId,
    influencerIds,
  }: {
    bucketId: number
    influencerIds: number[]
  }) {
    for (let i = buckets.value.length - 1; i >= 0; i--) {
      const elem = buckets.value[i]

      if (elem.id === bucketId) elem.influencers.push(...influencerIds)
    }
  }

  function REMOVE_INF_FROM_BUCKET({
    bucketId,
    influencerIds,
  }: {
    bucketId: number
    influencerIds: number[]
  }) {
    for (let i = buckets.value.length - 1; i >= 0; i--) {
      const elem = buckets.value[i]

      if (elem.id === bucketId) {
        elem.influencers = elem.influencers.filter(
          (infId) => !influencerIds.includes(infId),
        )
      }
    }
  }

  function SET_INSPECTED_INFLUENCERS(
    influencers: (Influencer | StatsV3Influencer)[] | null,
  ) {
    if (influencers) {
      const ids = influencers.map((i: Influencer | StatsV3Influencer) => i.id)
      inspectedInfluencers.value = influencers.filter(
        ({ id }: Influencer | StatsV3Influencer, index: number) =>
          !ids.includes(id, index + 1),
      )
      return
    }
    inspectedInfluencers.value = influencers
  }

  function SET_CAMPAIGN_ID(newCampaignId?: number) {
    campaignId.value = newCampaignId
  }

  function SET_ORIGIN_PAGE(newOriginPage: OriginPageName) {
    originPage.value = newOriginPage
  }

  function RESET() {
    while (buckets.value.length) buckets.value.splice(0, 1)

    inspectedInfluencers.value = null
    originPage.value = ''
  }

  async function FETCH_BUCKETS(
    { fetchRecommended } = { fetchRecommended: true },
  ) {
    try {
      const [data] = await Promise.all([
        coreFetch.$get<Bucket[]>('/agency/favorites/preview/'),
        ...(fetchRecommended
          ? [userFavoritesRecommendedStore.FETCH_BUCKETS()]
          : []),
      ])
      const bucketsToUpdate = data.filter((elem) => IDS.value.includes(elem.id))

      bucketsToUpdate.forEach((bucket: Bucket) => {
        UPDATE_BUCKET(bucket)
      })
      ADD_BUCKETS(
        data.filter((elem) => {
          return !IDS.value.includes(elem.id)
        }),
      )
      return data
    } catch (_) {
      return []
    }
  }

  function FETCH_INFLUENCERS(
    { fetchRecommended } = { fetchRecommended: true },
  ) {
    return (
      Promise.all([
        influencerStore.FETCH_SET([
          ...new Set(
            buckets.value.reduce((accumulator, bucket) => {
              return accumulator.concat(bucket.influencers)
            }, [] as number[]),
          ),
        ]),
        ...(fetchRecommended
          ? [userFavoritesRecommendedStore.FETCH_INFLUENCERS()]
          : []),
      ])
        // FIXME: make sure to return something so this can be used with `useAsyncData` without a warning
        // however, it's known that this call doesn't properly de-duplicate on server side
        // so we should investigate further and fix the return here to be more accurate
        .then((results) => results[0])
        .catch(() => [])
    )
  }

  async function FETCH_BUCKET(bucketId: number) {
    const bucket = await coreFetch.$get<Bucket>(`agency/favorites/${bucketId}/`)

    if (!IDS.value.includes(bucket.id)) ADD_BUCKET(bucket)
    else UPDATE_BUCKET(bucket)
  }

  async function DELETE_BUCKET(bucket: Bucket) {
    await coreFetch.$delete(`/agency/favorites/${bucket.id}/`)
    REMOVE_BUCKET(bucket.id)
  }

  async function PATCH_BUCKET(bucket: Bucket) {
    const resp = await coreFetch.$patch<Bucket>(
      `/agency/favorites/${bucket.id}/`,
      {
        influencers: bucket.influencers,
        name: bucket.name,
      },
    )

    UPDATE_BUCKET(resp)
  }

  return {
    // state
    buckets,
    inspectedInfluencers,
    originPage,
    campaignId,

    // getters
    GET_BUCKET_FROM_ID,
    BUCKET_EXISTS,
    IDS,
    INF_ID_IS_IN_BUCKET,
    ORIGIN_PAGE,
    CAMPAIGN_ID,

    // actions
    SET_BUCKETS,
    ADD_BUCKETS,
    ADD_BUCKET,
    REMOVE_BUCKET,
    REMOVE_BUCKETS,
    UPDATE_BUCKET,
    ADD_INF_FROM_BUCKET,
    REMOVE_INF_FROM_BUCKET,
    SET_INSPECTED_INFLUENCERS,
    SET_CAMPAIGN_ID,
    SET_ORIGIN_PAGE,
    RESET,
    FETCH_BUCKETS,
    FETCH_INFLUENCERS,
    FETCH_BUCKET,
    DELETE_BUCKET,
    PATCH_BUCKET,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserFavoritesStore, import.meta.hot),
  )
}
