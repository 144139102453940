import type { CoreFetch } from '~/types/CoreFetch'

export function provideGetDraftPromoByCode(coreFetch: CoreFetch) {
  return (code: string) =>
    coreFetch.$post<DraftPromoState>('/wallet/promo/', {
      code,
    })
}

export function provideUpdateDraftPromo(coreFetch: CoreFetch) {
  return (draftId: number, promoId: DraftPromoState['id'] | null) =>
    coreFetch.$patch<IDraftState>(`/submission/draft/${draftId}/`, {
      promo: promoId,
    })
}

export function provideAutoApplyBestPromo(coreFetch: CoreFetch) {
  return (draftId: number) =>
    coreFetch.$post<DraftState>(
      `/submission/draft/${draftId}/auto_apply_best_promo/`,
    )
}

export function provideValidatePromoCode(coreFetch: CoreFetch) {
  return (code: string, draftId: number, onlyApplyIfBetter = false) =>
    coreFetch.$post<DraftState>(
      `/submission/draft/${draftId}/validate_promo_apply/`,
      { code, override_existing: !onlyApplyIfBetter },
    )
}
