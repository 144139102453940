import type { CoreFetch } from '~/types/CoreFetch'
import type Track from '~/types/track'

export function providePatchTrack(coreFetch: CoreFetch) {
  return (trackId: number, track: Partial<Track>) =>
    coreFetch.$patch<DraftTrackState>(`/track/track/${trackId}/`, {
      ...track,
    })
}

export function provideCreateTrack(coreFetch: CoreFetch) {
  return (track: Partial<Track>) =>
    coreFetch.$post<DraftTrackState>('/track/track/', {
      ...track,
    })
}

export function provideGetTrack(coreFetch: CoreFetch) {
  return (trackId: number) =>
    coreFetch.$get<DraftTrackState>(`/track/track/${trackId}/`)
}

export function provideGetTrackTags(coreFetch: CoreFetch) {
  return (trackId: number) =>
    coreFetch.$get<TrackTagsState>(`/track/tags/`, {
      params: { track: trackId },
    })
}

export function providePatchTrackTags(coreFetch: CoreFetch) {
  return (trackId: number, tags: Partial<TrackTagsState>) =>
    coreFetch.$patch<TrackTagsState>(`/track/tags/`, tags, {
      params: { track: trackId },
    })
}
