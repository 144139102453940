import { useUserStore } from '~/stores/user'

import type { IdentifyData } from '~/types/Segment/SegmentIdentifyLibrary'

/**
 * Returns a function to make Segment "identify" calls with.
 *
 * @returns Function to call to send user data to Segment.
 */
export function useSegmentIdentify() {
  const { $segment, $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)

  /**
   * Calls Segment's `identify` function to associate user data with segment tracking
   * (see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify Segment identify documentation}).
   *
   * If no `user_id` prop is provided, it will automatically use the one from the user store.
   *
   * @param userData - User data to associate with segment tracking.
   */
  function segmentIdentify(userData?: IdentifyData) {
    const id = userStore.id
    const userId = userData?.user_id || id

    // don't send `user_id` as part of the userData object traits sent to Segment
    if (userData?.user_id)
      // eslint-disable-next-line dot-notation
      delete userData['user_id']

    return $segment.identify(userId.toString(), userData)
  }

  return segmentIdentify
}
