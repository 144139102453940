<template>
  <div class="mainParentContainer ignoreFontUpscale tw-pt-6 md:tw-pt-12">
    <InfluencerOnboardingFlowThiagoBubble>
      {{ $t(`influencer.signup.bubble.bio`) }}
    </InfluencerOnboardingFlowThiagoBubble>
    <div class="tw-mb-8 tw-flex tw-max-w-3xl tw-flex-col tw-gap-10 lg:tw-ml-48">
      <div class="tw-flex tw-flex-col tw-gap-2 tw-p-5">
        <VText cfg="sans/16/bold">
          {{ t('influencer.signup.bio.nudge.listHeader') }}
        </VText>
        <template
          v-for="(compellingItem, idx) in compellingBioItems"
          :key="idx"
        >
          <div class="tw-flex tw-items-baseline">
            <div
              class="tw-m-2 tw-mb-[6px] tw-aspect-square tw-min-w-2 tw-rounded-full tw-bg-black"
            ></div>
            <VText cfg="sans/16/normal" html-tag="p">
              {{ compellingItem }}
            </VText>
          </div>
        </template>
        <!-- DE link temporarily uses the EN one -->
        <a
          :href="t('influencer.signup.bio.nudge.faqLink')"
          rel="noopener noreferrer"
          target="_blank"
          class="tw-w-max"
        >
          <VText cfg="sans/16/normal" class="tw-flex tw-gap-1 tw-underline">
            {{ t('influencer.signup.bio.nudge.faq') }}
            <UIIcon name="tabler:external-link" />
          </VText>
        </a>
      </div>
      <VTextArea
        v-model="bioFieldValue"
        data-test-id="curatorSignupBioTextarea"
        :name="bioFieldName"
        :placeholder="$t('influencer.signup.bio.description.placeholder')"
        :label="$t('influencer.signup.bio.description.label')"
        :errmsg="$t('influencer.signup.bio.description.error')"
        :p-validity="!bioFieldErrorMessage"
      >
        <template #info>
          <VText
            v-if="!bioFieldErrorMessage"
            cfg="sans/14/regular"
            color="gray-500"
          >
            {{ $t('influencer.signup.bio.description.helper') }}
          </VText>
        </template>
      </VTextArea>
    </div>
    <InfluencerOnboardingFlowNavigationFooter @next="handleNext" />
  </div>
</template>

<script lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'
import { mapActions, mapState, mapStores } from 'pinia'
import { useForm } from 'vee-validate'
import { z } from 'zod'

import InfluencerOnboardingFlowNavigationFooter from '~/components/influencer/signup/InfluencerOnboardingFlowNavigationFooter.vue'
import InfluencerOnboardingFlowThiagoBubble from '~/components/influencer/signup/InfluencerOnboardingFlowThiagoBubble.vue'
import VTextArea from '~/components/ui/Inputs/VTextarea.vue'
import VText from '~/components/ui/VText.vue'

import { useCuratorOnboardingFlow } from '~/composables/useCuratorOnboardingFlow'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'
import { useUserInfluencerStore } from '~/stores/userInfluencer'
import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

import { provideCuratorRefreshCache } from '~/api-core/Curators/CuratorRefreshCache'
import { providePatchInfluencer } from '~/api-core/Curators/PatchInfluencer'

import type { Influencer } from '~/types/influencer'

export default defineNuxtComponent({
  components: {
    InfluencerOnboardingFlowNavigationFooter,
    InfluencerOnboardingFlowThiagoBubble,
    VText,
    VTextArea,
    UIIcon,
  },
  setup() {
    definePageMeta({
      layout: 'onboarding-flow',
      middleware: ['onboarding-flow'],
    })

    const router = useRouter()
    const { t } = useI18n()
    const { $pinia } = useNuxtApp()
    const { coreFetch } = useProvideCoreFetch()
    const userInfluencerStore = useUserInfluencerStore($pinia)
    const influencerOnboardingFlowStore =
      useInfluencerOnboardingFlowStore($pinia)
    const { wordCountCheck } = useCuratorOnboardingFlow()
    const { GET_TAG_TYPE_FROM_NAME, GET_TAGS_FROM_IDS } = useTagStore($pinia)

    influencerOnboardingFlowStore.CHECK_ACTIVE_STEP(
      router.currentRoute.value.path,
    )

    const compellingBioItems = [
      t('influencer.signup.bio.nudge.compellingItems.1'),
      t('influencer.signup.bio.nudge.compellingItems.2'),
      t('influencer.signup.bio.nudge.compellingItems.3'),
      t('influencer.signup.bio.nudge.compellingItems.4'),
    ]

    const { values: formValues, validate: validateForm } = useForm<{
      bio: string
    }>({
      initialValues: {
        bio: userInfluencerStore.description_en,
      },
    })
    const bioFieldName = 'bio'
    const { value: bioFieldValue, errorMessage: bioFieldErrorMessage } =
      useFieldValidation<string>(
        bioFieldName,
        z
          .string()
          .refine(
            (s) => wordCountCheck(s),
            t('influencer.signup.bio.description.error'),
          ),
        { syncVModel: true, validateOnValueUpdate: false },
      )

    return {
      t,
      router,
      formValues,
      validateForm,
      bioFieldName,
      bioFieldValue,
      bioFieldErrorMessage,
      compellingBioItems,
      coreFetch,
      GET_TAG_TYPE_FROM_NAME,
      GET_TAGS_FROM_IDS,
    }
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useUserInfluencerStore),
    ...mapStores(useUserInfluencerTagsStore),
    USER() {
      return this.userStore.$state
    },
    INFLUENCER() {
      return this.userInfluencerStore.$state
    },
  },
  methods: {
    ...mapActions(useInfluencerOnboardingFlowStore, {
      GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
    }),
    ...mapActions(useUserInfluencerStore, { SET_INFLUENCER: 'SET' }),
    ...mapActions(useUserInfluencerTagsStore, {
      UPDATE_INFLUENCER_TAGS_FROM_PATCH: 'UPDATE_FROM_PATCH',
    }),
    async patchInfluencerBio(): Promise<Influencer> {
      return await providePatchInfluencer(this.coreFetch, this.INFLUENCER.id, {
        description_en: this.bioFieldValue,
      })()
    },
    async refreshCache() {
      await provideCuratorRefreshCache(this.coreFetch)(this.INFLUENCER.id)
    },
    async handleNext() {
      const { valid, errors } = await this.validateForm()
      if (!valid) {
        useScrollFieldWithErrorIntoView(errors)
        return
      }

      try {
        const influencer = await this.patchInfluencerBio()

        this.SET_INFLUENCER(influencer)

        await this.refreshCache()

        this.GO_TO_NEXT_STEP()
      } catch (error) {
        this.$sentry?.captureException(error)
      }
    },
  },
})
</script>
