import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftStore } from './draft'
import { useUserStore } from './user'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { provideFetchCartPricingInfo } from '~/api-core/Campaigns/FetchCartPricingInfo'

import type { BackendDraftPricingInfo, DraftPricingInfo } from '~/types/draft'

const initialState = (): DraftPricingInfo => ({
  minimumCartCost: -1,
  isDraftFirstPayingCampaign: true,
})

export const useDraftPricingStore = defineStore('draftPricing', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const userStore = useUserStore($pinia)
  const draftStore = useDraftStore($pinia)

  // state
  const minimumCartCost = ref(initialState().minimumCartCost)
  const isDraftFirstPayingCampaign = ref(
    initialState().isDraftFirstPayingCampaign,
  )

  // getters
  const $state = computed(() => ({
    minimumCartCost: minimumCartCost.value,
    isDraftFirstPayingCampaign: isDraftFirstPayingCampaign.value,
  }))

  const MISSING = computed(() => {
    const currentGroovizAmount = userStore.grooviz
    const postPromotionCost = draftStore.DRAFT_COST_AFTER_PROMO
    return Math.abs(Math.min(currentGroovizAmount - postPromotionCost, 0))
  })

  const NEED_PURCHASE = computed((): boolean => {
    return MISSING.value > 0
  })

  const REMAINING = computed(() => {
    const currentGroovizAmount = userStore.grooviz
    const postPromotionCost = draftStore.DRAFT_COST_AFTER_PROMO
    return Math.floor(currentGroovizAmount - postPromotionCost)
  })

  const ADJUSTED_MISSING = computed((): number => {
    return Math.max(MISSING.value, minimumCartCost.value)
  })

  // actions
  function SET_CART_PRICING_INFO(newState: DraftPricingInfo): void {
    minimumCartCost.value = newState.minimumCartCost
    isDraftFirstPayingCampaign.value = newState.isDraftFirstPayingCampaign
  }

  function RESET_CART_PRICING_INFO(): void {
    SET_CART_PRICING_INFO(initialState())
  }

  function FETCH_CART_PRICING_INFO(): Promise<DraftPricingInfo> {
    const fetchCartPricingInfo = provideFetchCartPricingInfo(coreFetch)

    if (minimumCartCost.value !== initialState().minimumCartCost)
      return Promise.resolve($state.value)

    return fetchCartPricingInfo()
      .then(
        ({
          minimum_cart_cost: minimumCartCost,
          has_updated_minimum_cart_cost: isDraftFirstPayingCampaign,
        }: BackendDraftPricingInfo) => {
          const newState = {
            minimumCartCost,
            isDraftFirstPayingCampaign,
          } satisfies DraftPricingInfo

          SET_CART_PRICING_INFO(newState)
          return newState
        },
      )
      .catch((err) => {
        RESET_CART_PRICING_INFO()
        return err
      })
  }

  return {
    // state
    minimumCartCost,
    isDraftFirstPayingCampaign,

    // actions
    SET_CART_PRICING_INFO,
    RESET_CART_PRICING_INFO,
    FETCH_CART_PRICING_INFO,

    // getters
    NEED_PURCHASE,
    REMAINING,
    MISSING,
    ADJUSTED_MISSING,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftPricingStore, import.meta.hot))
