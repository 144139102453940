import {
  extrapolateLanguageFromLocale,
  getLocaleFromLanguage,
  useLanguage,
} from '~/composables/useLanguage'

import { useRootStore } from '~/stores/root'
import { useUserStore } from '~/stores/user'

import type { Locale } from '~/types/locale'
import type { Composer } from 'vue-i18n'

export default defineNuxtPlugin({
  async setup({ $i18n }) {
    if (import.meta.env.TEST) return

    const {
      $router: { currentRoute: route },
      $pinia,
      $i18n: { setLocale },
    } = useNuxtApp()
    const switchLocalePath = useSwitchLocalePath()
    const { getCurrentLanguage, setCurrentLanguage } = useLanguage()
    // We're extrapolating a little here, but as long as we're using the i18n module this should hold true.
    const { locale } = $i18n as Composer

    // Skipping user load for widgets page
    if (route.value?.name?.toString().includes('influencer-widget-slug')) return

    const rootStore = useRootStore($pinia)
    const userStore = useUserStore($pinia)

    // use useAsyncData here to de-duplicate calls when hydrating the client
    await useAsyncData('userLoad', async () => await rootStore.USER_LOAD())
    await useAsyncData(
      'accountsLoad',
      async () => await rootStore.ACCOUNTS_LOAD(),
    )

    // Make sure that target Locale is respected post serverside login
    const targetLocale = getLocaleFromLanguage(getCurrentLanguage())

    // When logged in we want to adjust app locale to what the user last selected
    if (userStore.IS_LOGGED_IN && locale.value !== targetLocale) {
      // Replace any duplicate `/` that might be generated by switchLocalePath :<
      const targetPath = switchLocalePath(targetLocale).replace(/\/+/gi, '/')

      await setLocale(targetLocale)
      await navigateTo(targetPath, { replace: true })
    } else if (locale.value !== targetLocale) {
      // If locale is not the one selected in the URL make sure to adjust
      await setCurrentLanguage(
        extrapolateLanguageFromLocale(locale.value as Locale),
      )
    }
  },
})
