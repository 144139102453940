import type { CoreFetch } from '~/types/CoreFetch'
import type { Influencer } from '~/types/influencer'
import type { StoreInfluencerInteraction } from '~/types/influencerInteraction'

export function provideGetCuratorById(coreFetch: CoreFetch) {
  return function (influencerId: Influencer['id']): Promise<Influencer> {
    return coreFetch.$get(`/influencer/profile/${influencerId}/`)
  }
}

export function provideGetCuratorBySlug(coreFetch: CoreFetch) {
  return function (influencerSlug: Influencer['slug']): Promise<Influencer> {
    return coreFetch.$get(`/influencer/profile/${influencerSlug}/`)
  }
}

export function provideGetInfluencerInteractions(coreFetch: CoreFetch) {
  return function (): Promise<StoreInfluencerInteraction[]> {
    return coreFetch.$get<StoreInfluencerInteraction[]>(
      '/influencer/interactions/',
    )
  }
}
