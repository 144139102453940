/* eslint-disable camelcase */
import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useMiscDraftProgressivePromosStore } from './miscDraftProgressivePromos'

import { provideGetDraftPromoByCode } from '~/api-core/Draft/DraftPromo'

import type { ProgressivePromo } from '~/types/product'

const initialState = () => ({
  id: 0,
  code: '',
  is_percentage: false,
  auto_apply: false,
  actions: {
    campaign: {
      value: 0,
      is_percentage: true,
    },
    hype: {
      value: 0,
      is_percentage: true,
    },
  },
  rule: '',
  progressive_parameters: undefined as
    | undefined
    | ProgressivePromo['progressive_parameters'],
})

const state = initialState()
export type DraftPromoState = typeof state

export const useDraftPromoStore = defineStore('draftPromo', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const miscDraftProgressivePromosStore =
    useMiscDraftProgressivePromosStore($pinia)

  const id = ref(state.id)
  const code = ref(state.code)
  const is_percentage = ref(state.is_percentage)
  const auto_apply = ref(state.auto_apply)
  const actions = ref(state.actions)
  const progressive_parameters = ref(state.progressive_parameters)
  const rule = ref(state.rule)

  const $state = computed(() => ({
    id: id.value,
    code: code.value,
    is_percentage: is_percentage.value,
    auto_apply: auto_apply.value,
    actions: actions.value,
    progressive_parameters: progressive_parameters.value,
    rule: rule.value,
  }))

  const IS_PROGRESSIVE_PROMO = computed(() => {
    return miscDraftProgressivePromosStore.list
      .map((promo) => promo.code)
      .includes(code.value)
  })

  function getStateRef(key: keyof DraftPromoState) {
    switch (key) {
      case 'id':
        return id
      case 'code':
        return code
      case 'is_percentage':
        return is_percentage
      case 'auto_apply':
        return auto_apply
      case 'actions':
        return actions
      case 'progressive_parameters':
        return progressive_parameters
      case 'rule':
        return rule
    }
  }

  function SET(patch: Partial<Record<keyof DraftPromoState, any>>) {
    Object.keys(patch).forEach((key) => {
      const stateRef = getStateRef(key as keyof DraftPromoState)
      if (stateRef === undefined) return

      stateRef.value = patch[key as keyof DraftPromoState]
    })
  }

  function TEST_VALIDITY() {
    const getPromoCode = provideGetDraftPromoByCode(coreFetch)
    return getPromoCode(code.value)
  }

  function RESET() {
    SET(initialState())
  }

  return {
    // state
    id,
    code,
    is_percentage,
    auto_apply,
    actions,
    progressive_parameters,
    rule,

    // getters
    $state,
    IS_PROGRESSIVE_PROMO,

    // actions
    SET,
    TEST_VALIDITY,
    RESET,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftPromoStore, import.meta.hot))
