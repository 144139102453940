import { getCoreBaseUrl } from '~/helpers/api/getBaseUrl'
import {
  defineCoreFetch,
  getFetchWithDefaults,
  GROOVER_CSRF_COOKIE,
} from '~/helpers/fetch'

export function useProvideCoreFetch() {
  const runtimeConfig = useRuntimeConfig()
  const csrfCookie = useCookie(GROOVER_CSRF_COOKIE, { watch: true })
  const cookieRequestHeaders = useRequestHeaders(['cookie'])

  const coreFetch = defineCoreFetch(
    getFetchWithDefaults(
      {
        baseURL: getCoreBaseUrl(runtimeConfig),
      },
      cookieRequestHeaders,
      csrfCookie.value || undefined,
    ),
  )

  return {
    coreFetch,
  }
}
