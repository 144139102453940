import type { SubmissionMetaResponse } from '~/stores/user'
import type { CoreFetch } from '~/types/CoreFetch'

export function fetchSubmissionMetaData(coreFetch: CoreFetch) {
  return function () {
    return coreFetch.$get<SubmissionMetaResponse>(
      '/submission/submission/meta/',
    )
  }
}
