export function useExtractWidgetCookieContext() {
  const route = useRoute()
  const fromWidgetCookieName = 'from_widget'
  const fromWidgetCookie = useCookie(fromWidgetCookieName)

  const hadCookie = computed(() => fromWidgetCookie.value)

  const fromWidgetId = computed(() => {
    return typeof route.query.widget_id === 'string'
      ? route.query.widget_id
      : typeof hadCookie === 'string'
        ? hadCookie
        : ''
  })

  const fromWidget = computed(() => Boolean(fromWidgetId.value))

  return {
    hadCookie,
    fromWidgetId,
    fromWidget,
    fromWidgetCookieName,
  }
}
