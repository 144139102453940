<template>
  <div class="mainParentContainer ignoreFontUpscale tw-pt-6 md:tw-pt-12">
    <InfluencerOnboardingFlowThiagoBubble>
      {{ $t(`influencer.signup.bubble.similar-artists`) }}
    </InfluencerOnboardingFlowThiagoBubble>
    <div class="tw-max-w-3xl lg:tw-ml-32">
      <div class="tw-flex tw-flex-col tw-gap-2">
        <SpotifyArtistsSelect
          id="editSpotifyArtists"
          data-test-id="curatorSignupSimilarArtistsSelect"
          :current-artists="currentArtists"
          :label="$t(`influencer.signup.similar-artists.label`)"
          :err-msg="$t('influencer.signup.similar-artists.error')"
          :has-error="displayErrorMessage"
          :max-artists="maxArtists"
          :show-cant-find-message="false"
          @update:model-value="handleInput"
        />
        <VText v-if="displayErrorMessage" cfg="sans/14/medium" color="error">
          {{ $t('influencer.signup.similar-artists.error') }}
        </VText>
      </div>
    </div>
    <InfluencerOnboardingFlowNavigationFooter @next="handleNext" />
  </div>
</template>

<script lang="ts">
import { mapActions, mapStores } from 'pinia'

import InfluencerOnboardingFlowNavigationFooter from '~/components/influencer/signup/InfluencerOnboardingFlowNavigationFooter.vue'
import InfluencerOnboardingFlowThiagoBubble from '~/components/influencer/signup/InfluencerOnboardingFlowThiagoBubble.vue'
import SpotifyArtistsSelect from '~/components/ui/SpotifyArtistsSelect.vue'
import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useUserStore } from '~/stores/user'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

import { provideCuratorRefreshCache } from '~/api-core/Curators/CuratorRefreshCache'
import { providePatchInfluencer } from '~/api-core/Curators/PatchInfluencer'

import type { Influencer } from '~/types/influencer'
import type SpotifyArtist from '~/types/spotifyArtist'

export default defineNuxtComponent({
  components: {
    InfluencerOnboardingFlowNavigationFooter,
    InfluencerOnboardingFlowThiagoBubble,
    SpotifyArtistsSelect,
    VText,
  },
  setup() {
    definePageMeta({
      layout: 'onboarding-flow',
      middleware: ['onboarding-flow'],
    })

    const { coreFetch } = useProvideCoreFetch()
    const segmentTrack = useSegmentTrack()

    return {
      coreFetch,
      segmentTrack,
    }
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useUserInfluencerStore),
    USER() {
      return this.userStore.$state
    },
    INFLUENCER() {
      return this.userInfluencerStore.$state
    },
  },
  data() {
    return {
      currentArtists: [] as SpotifyArtist[],
      maxArtists: 20,
      displayErrorMessage: false,
    }
  },
  created() {
    if (this.INFLUENCER.similar_artists)
      this.currentArtists = this.INFLUENCER.similar_artists
  },
  methods: {
    ...mapActions(useInfluencerOnboardingFlowStore, {
      GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
    }),
    ...mapActions(useUserInfluencerStore, { SET_INFLUENCER: 'SET' }),
    async patchInfluencerSimilarArtists(): Promise<Influencer> {
      return await providePatchInfluencer(this.coreFetch, this.INFLUENCER.id, {
        similar_artists: this.currentArtists,
      })()
    },
    async refreshCache() {
      await provideCuratorRefreshCache(this.coreFetch)(this.INFLUENCER.id)
    },
    async handleNext() {
      if (this.currentArtists.length === 0) {
        this.displayErrorMessage = true
        return
      }

      this.displayErrorMessage = false

      try {
        const influencer = await this.patchInfluencerSimilarArtists()

        this.SET_INFLUENCER(influencer)

        await this.refreshCache()

        this.trackEvent()

        this.GO_TO_NEXT_STEP()
      } catch (error) {
        this.$sentry?.captureException(error)
      }
    },
    handleInput(selectedArtists: SpotifyArtist[]): void {
      if (selectedArtists.length > 0) this.displayErrorMessage = false

      this.currentArtists = selectedArtists
    },
    trackEvent() {
      const similarArtists = this.currentArtists.map(
        (artist: SpotifyArtist) => artist.name,
      )

      this.segmentTrack('Curator Onboarding - Similar Artists Step Finalized', {
        curator_id: this.INFLUENCER.id,
        curator_similar_artists_list: similarArtists,
        has_modified_similar_artists: Boolean(similarArtists.length),
      })
    },
  },
})
</script>
