import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { isNonEmptyObject } from '~/utils'

import type TagType from '~/entities/tagType'
import type { FilterCategory } from '~/enums/FilterCategory'
import type CampaignGoal from '~/types/campaignGoal'
import type { InteractionTypeKey } from '~/types/filters'

export type InteractionStats = Record<InteractionTypeKey, number>
type StatsRecord = Record<number, number>

const state = () => ({
  selected_goals: [] as CampaignGoal[],
  selected_tag_ids: [] as number[],
  backup_tag_ids: [] as number[],
  selected_influencer_ids: [] as number[],
  tag_stats: {} as StatsRecord,
  parent_stats: {} as StatsRecord,
  interaction_stats: {
    contacted: 0,
    not_contacted: 0,
    new: 0,
    with_highly_rated_by_me: 0,
    with_positive_response: 0,
  } as InteractionStats,
  loading: false,
  scrollPos: 0,
  displayFilters: false,
  expandedMenuIndex: -1,
  pillsOrder: [] as FilterCategory[],
})

export type MiscSendtrackFiltersState = ReturnType<typeof state>

export const useMiscSendtrackFiltersStore = defineStore(
  'miscSendtrackFilters',
  () => {
    const { coreFetch } = useProvideCoreFetch()
    const { $pinia } = useNuxtApp()
    const influencersStore = useInfluencersStore($pinia)
    const userStore = useUserStore($pinia)
    const userBandStore = useUserBandStore($pinia)
    const tagStore = useTagStore($pinia)

    /**
     * @deprecated goals were part of a legacy feature that no longer exists and should be removed.
     */
    const selected_goals = ref<CampaignGoal[]>([])
    const selected_tag_ids = ref<number[]>([])
    const backup_tag_ids = ref<number[]>([])
    const selected_influencer_ids = ref<number[]>([])
    const tag_stats = ref<StatsRecord>({})
    const parent_stats = ref<StatsRecord>({})
    const interaction_stats = ref<InteractionStats>({
      contacted: 0,
      not_contacted: 0,
      new: 0,
      with_highly_rated_by_me: 0,
      with_positive_response: 0,
    })
    const loading = ref<boolean>(false)
    const scrollPos = ref<number>(0)
    const displayFilters = ref<boolean>(false)
    const expandedMenuIndex = ref<number>(-1)
    const pillsOrder = ref<FilterCategory[]>([])
    const formatedQuery = ref<string>('')

    const DISPLAY_FILTERS = computed(() => {
      return displayFilters.value
    })

    const NB_FILTER_SELECTED = computed(() => {
      return selected_tag_ids.value.length
    })

    const SEND_DATA = computed<Record<TagType['name'], number[]>>(() => {
      return tagStore.types.reduce(
        (accumulator, tagType) => {
          const tagIdArray = selected_tag_ids.value.filter((selectedTagId) =>
            tagType.tag_ids.includes(selectedTagId),
          )

          if (tagIdArray.length) accumulator[tagType.name] = tagIdArray

          return accumulator
        },
        {} as Record<TagType['name'], number[]>,
      )
    })

    const FORMATTED_QUERY = computed(() => {
      const entries = Object.entries(SEND_DATA.value) as [
        TagType['name'],
        number[],
      ][]
      const formatedStrings = entries.map(([key, values]) => ({
        key,
        values: values.join(','),
      }))
      const out =
        '?' +
        formatedStrings.map((entry) => `${entry.key}=${entry.values}`).join('&')

      return out
    })

    function SET_BACKUP_TAGS_IDS(idArray: number[]) {
      backup_tag_ids.value = idArray
    }

    function SET_SELECTED_GOALS(goals: CampaignGoal[]) {
      selected_goals.value = goals
    }

    function SET_SELECTED_TAG_IDS(idArray: number[]) {
      selected_tag_ids.value = idArray
    }

    function SET_SELECTED_INFLUENCER_IDS(idArray: number[]) {
      selected_influencer_ids.value = idArray
    }

    function SET_TAG_STATS(stats: StatsRecord) {
      tag_stats.value = stats
    }

    function SET_PARENT_STATS(stats: StatsRecord) {
      parent_stats.value = stats
    }

    function SET_LOADING(payload: boolean) {
      loading.value = payload
    }

    function SET_DISPLAY_FILTERS(payload: boolean) {
      displayFilters.value = payload
    }

    function SET_SCROLL_POS(newPosition: number) {
      scrollPos.value = newPosition
    }

    function SET_INTERACTION_STATS(interactionStats: InteractionStats) {
      interaction_stats.value = {
        ...interaction_stats.value,
        ...interactionStats,
      }
    }

    function SET_PILLS_ORDER(newPillsOrder: FilterCategory[]) {
      pillsOrder.value = newPillsOrder
    }

    function SET_EXPANDED_MENU(target: FilterCategory | 'none' | number) {
      const updateIndex = (i: number) => (expandedMenuIndex.value = i)

      if (typeof target === 'number') return updateIndex(target)

      const newIndex =
        target === 'none'
          ? -1
          : pillsOrder.value.findIndex(
              (categoryName) => categoryName === target,
            )
      updateIndex(newIndex)
    }

    async function FETCH_STATS(): Promise<boolean> {
      const [tagStats, parentStats, interactionStats]: [
        StatsRecord,
        StatsRecord,
        InteractionStats,
      ] = await Promise.all([
        isNonEmptyObject(tag_stats.value)
          ? tag_stats.value
          : coreFetch.$get<StatsRecord>('/influencer/tag_counts/'),
        isNonEmptyObject(parent_stats.value)
          ? parent_stats.value
          : coreFetch.$get<StatsRecord>('/influencer/parent_counts/'),
        userStore.IS_BAND
          ? coreFetch.$get<InteractionStats>(
              `/influencer/interaction_counts/?band_id=${userBandStore.id}`,
            )
          : interaction_stats.value,
      ]).catch(() => {
        return [{} as StatsRecord, {} as StatsRecord, {} as InteractionStats]
      })

      if (
        !isNonEmptyObject(tagStats) &&
        !isNonEmptyObject(parentStats) &&
        !isNonEmptyObject(interactionStats)
      )
        return false

      SET_TAG_STATS(tagStats)
      SET_PARENT_STATS(parentStats)
      SET_INTERACTION_STATS(interactionStats)

      return true
    }

    function FETCH(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        Promise.all([FETCH_STATS(), callOnce('FETCH_TAGS', tagStore.FETCH)])
          .then(() => resolve(true))
          .catch((err) => reject(err))
      })
    }

    function EXECUTE() {
      SET_LOADING(true)

      return coreFetch
        .$get<number[]>(`/influencer/query/${FORMATTED_QUERY.value}`)
        .then(async (resp) => {
          await checkForMissingInfs(influencersStore.IDS, resp)
          SET_SELECTED_INFLUENCER_IDS(resp)
        })
        .finally(() => {
          SET_LOADING(false)
        })
    }

    function BACKUP_FILTER_SELECTION(): void {
      SET_BACKUP_TAGS_IDS([...selected_tag_ids.value])
    }

    function REVERT_TO_BACKUP_SELECTION(): void {
      SET_SELECTED_TAG_IDS([...backup_tag_ids.value])
    }

    function EMPTY() {
      SET_SELECTED_TAG_IDS([])
      SET_SELECTED_GOALS([])
    }

    /**
     * Internal function checks if we receive inf. Ids that aren't in the vuex store and fetches them.
     *
     * @param storedIds - The ids we currently have in the store.
     * @param idsFromFilterResponse - The list of ids to check.
     */
    async function checkForMissingInfs(
      storedIds: number[],
      idsFromFilterResponse: number[],
    ) {
      // find difference of infs and fetch them
      const missingIds = idsFromFilterResponse.filter(
        (id: number) => !storedIds.includes(id),
      )
      if (!missingIds.length) return

      // if there are missing infs, go get them and add them to the list
      const batchResponse = await influencersStore.FETCH_SET_AXIOS(missingIds)
      await influencersStore.HANDLE_INFLUENCER_BATCH({
        response: batchResponse,
        config: {
          ...influencersStore.defaultConfig,
          noFollow: true,
          noFetchRecommendation: true,
        },
      })
    }

    return {
      // state
      /**
       * @deprecated goals were part of a legacy feature that no longer exists and should be removed.
       */
      selected_goals,
      selected_tag_ids,
      backup_tag_ids,
      selected_influencer_ids,
      tag_stats,
      parent_stats,
      interaction_stats,
      loading,
      scrollPos,
      displayFilters,
      expandedMenuIndex,
      pillsOrder,
      formatedQuery,

      // actions
      SET_BACKUP_TAGS_IDS,
      SET_SELECTED_GOALS,
      SET_SELECTED_TAG_IDS,
      SET_SELECTED_INFLUENCER_IDS,
      SET_TAG_STATS,
      SET_PARENT_STATS,
      SET_LOADING,
      SET_DISPLAY_FILTERS,
      SET_SCROLL_POS,
      SET_INTERACTION_STATS,
      SET_PILLS_ORDER,
      SET_EXPANDED_MENU,
      FETCH_STATS,
      FETCH,
      EXECUTE,
      BACKUP_FILTER_SELECTION,
      REVERT_TO_BACKUP_SELECTION,
      EMPTY,

      // getters
      DISPLAY_FILTERS,
      NB_FILTER_SELECTED,
      SEND_DATA,
      FORMATTED_QUERY,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscSendtrackFiltersStore, import.meta.hot),
  )
}
