import { defu } from 'defu'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { nextTick } from 'vue'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import type { Band } from '~/types/band'
import type { TagTarget } from '~/types/tagTarget'
import type { TagTypeKey } from '~/types/tagTypeKey'
import type { DeepPartial } from '~/types/utils'

export type UserBandSetState = Pick<
  ReturnType<typeof useUserBandSetStore>,
  'list'
>

export const useUserBandSetStore = defineStore('userBandSet', () => {
  const { coreFetch } = useProvideCoreFetch()

  // state
  const list = ref<Band[]>([])

  // getters
  function GET_BAND_INDEX_FROM_ID(bandId: number) {
    return list.value.findIndex((band) => {
      return band.id === bandId
    })
  }

  function GET_BAND_FROM_ID(bandId: number) {
    return list.value.find((band) => {
      return band.id === bandId
    })
  }

  function GET_BAND_INDEX_FROM_SLUG(bandSlug: string) {
    return list.value.findIndex((band) => {
      return band.slug === bandSlug
    })
  }

  const LIVE_BANDS = computed(() => {
    return list.value.filter((band) => !band.archived)
  })

  const TRACK_COUNT = computed(() => {
    return list.value.reduce((accumulator, band) => {
      return accumulator + band.track_set.length
    }, 0)
  })

  // actions
  function RESET() {
    list.value = []
  }

  function SET(bandSet: Band[]) {
    for (let i = bandSet.length - 1; i >= 0; i--) {
      if (!bandSet[i].media_links) {
        bandSet[i].media_links = {
          instagram: '',
          facebook: '',
          youtube: '',
          deezer: '',
          spotify: '',
          bandcamp: '',
          soundcloud: '',
          twitter: '',
          tiktok: '',
          pk: 0,
          id: 0,
        }
      }

      list.value[i] = bandSet[i]
    }
  }

  function EDIT_BAND_INDEX_FROM_TARGET_AND_TYPE({
    index,
    tagTarget,
    tagType,
    patch,
  }: {
    index: number
    tagTarget: TagTarget
    tagType: TagTypeKey
    patch: number[]
  }) {
    // @ts-expect-error
    list.value[index].tags[tagTarget as 'identity'][tagType] = patch
  }

  function UPDATE_INDEX_WITH_PATCH({
    band_patch,
    index,
  }: {
    band_patch: DeepPartial<Band>
    index: number
  }) {
    let key: keyof Band

    for (key in band_patch) {
      const data = band_patch[key]
      const updateKey = list.value[index][key]

      if (typeof data === typeof updateKey)
        // @ts-expect-error
        list.value[index][key] = data
    }
  }

  function ADD_TRACK_TO_INDEX({
    band_index,
    track_id,
  }: {
    band_index: number
    track_id: number
  }) {
    if (band_index !== -1) {
      const track_set = list.value[band_index].track_set

      if (track_set && !track_set.includes(track_id)) {
        list.value.splice(band_index, 1, {
          ...list.value[band_index],
          track_set: [...track_set, track_id],
        })
      }
    }
  }

  async function FETCH() {
    const bandSet: Band[] = await GET()
    RESET()
    SET(bandSet)
    return bandSet
  }

  async function GET() {
    const { results }: { results: Band[] } = await coreFetch
      .$get('/band/band/self/')
      .catch(() => ({ results: [] }))
    return results || []
  }

  function ADD_TRACK_TO_BAND({
    band_id,
    track_id,
  }: {
    band_id: number
    track_id: number
  }) {
    ADD_TRACK_TO_INDEX({
      track_id,
      band_index: GET_BAND_INDEX_FROM_ID(band_id),
    })
  }

  function EDIT_BAND_ID_TAGS_FROM_TARGET_AND_TYPE({
    bandId,
    tagTarget,
    tagType,
    patch,
  }: {
    bandId: number
    tagTarget: TagTarget
    tagType: TagTypeKey
    patch: number[]
  }) {
    const index = GET_BAND_INDEX_FROM_ID(bandId)

    if (index !== -1) {
      EDIT_BAND_INDEX_FROM_TARGET_AND_TYPE({
        index,
        tagTarget,
        tagType,
        patch,
      })
    } else {
      console.warn(`Could not find target band with id : ${bandId}`)
    }
  }

  function MERGE_BAND_PATCH({
    band_patch: bandPatch,
    band_id: bandId,
  }: {
    band_patch: DeepPartial<Band>
    band_id?: number
  }) {
    const id = bandId || bandPatch?.id || 0
    const index = GET_BAND_INDEX_FROM_ID(id)
    if (!id || index === -1) return

    const initialTags = defu(
      defu(list.value[index].tags || {}, {
        identity: {
          subgenre: [],
          mood: [],
          artist_kind: [],
          country: [],
        },
      }),
      bandPatch.tags || {},
    )

    UPDATE_INDEX_WITH_PATCH({
      band_patch: { ...bandPatch, tags: initialTags },
      index,
    })

    if (!bandPatch.tags) return

    Object.entries(bandPatch.tags).forEach(([tagTarget, targetObj]) => {
      Object.entries(targetObj).forEach(([tagType, patch]) => {
        EDIT_BAND_ID_TAGS_FROM_TARGET_AND_TYPE({
          bandId: id,
          tagTarget: tagTarget as TagTarget,
          tagType: tagType as TagTypeKey,
          patch: patch ?? [],
        })
      })
    })
  }

  async function FORCE_PICTURE_REFRESH(noFollow = false): Promise<void> {
    for (let i = list.value.length - 1; i >= 0; i--) {
      UPDATE_INDEX_WITH_PATCH({
        index: i,
        band_patch: {
          has_profile_picture: !list.value[i].has_profile_picture,
        },
      })
    }

    if (!noFollow) {
      await nextTick()
      await FORCE_PICTURE_REFRESH(true)
    }
  }

  return {
    // state
    list,

    // getters
    GET_BAND_INDEX_FROM_ID,
    GET_BAND_FROM_ID,
    GET_BAND_INDEX_FROM_SLUG,
    LIVE_BANDS,
    TRACK_COUNT,

    // actions
    RESET,
    SET,
    EDIT_BAND_INDEX_FROM_TARGET_AND_TYPE,
    UPDATE_INDEX_WITH_PATCH,
    ADD_TRACK_TO_INDEX,
    FETCH,
    FORCE_PICTURE_REFRESH,
    GET,
    ADD_TRACK_TO_BAND,
    EDIT_BAND_ID_TAGS_FROM_TARGET_AND_TYPE,
    MERGE_BAND_PATCH,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserBandSetStore, import.meta.hot))
