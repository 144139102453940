import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import patcherShorthand from '~/mixins/store/patcher'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useUserStore } from '~/stores/user'

import type {
  InfluencerLikedNeutralHatedTags,
  InfluencerTags,
} from '~/types/influencer'
import type { TagParentTypeKey } from '~/types/tagParentTypeKey'
import type { TagTarget } from '~/types/tagTarget'
import type { TagTypeKey } from '~/types/tagTypeKey'
import type { DeepPartial } from '~/types/utils'

const common = (): InfluencerLikedNeutralHatedTags => ({
  subgenre: [],
  mood: [],
  format: [],
  artist_kind: [],
})

const initialState = () => {
  return {
    liked: {
      ...common(),
    },
    hated: {
      ...common(),
    },
    neutral: {
      ...common(),
    },
    identity: {
      influencer_kind: [],
      country: [],
      influencer_badge: [],
    },
    exclusivity: {
      country: [],
      lyrics_lang: [],
      track_age: [],
    },
  } as InfluencerTags
}

// TODO if we already have InfluencerTags do we need the following one?
export type UserInfluencerTagsState = ReturnType<typeof initialState>

type InfluencerTagsTarget = keyof UserInfluencerTagsState

export const useUserInfluencerTagsStore = defineStore(
  'userInfluencerTags',
  () => {
    const { coreFetch } = useProvideCoreFetch()
    const { $pinia } = useNuxtApp()
    const userStore = useUserStore($pinia)

    const liked = ref<InfluencerLikedNeutralHatedTags>(common())
    const hated = ref<InfluencerLikedNeutralHatedTags>(common())
    const neutral = ref<InfluencerLikedNeutralHatedTags>(common())
    const identity = ref<InfluencerTags['identity']>({
      influencer_kind: [],
      country: [],
      influencer_badge: [],
    })
    const exclusivity = ref<InfluencerTags['exclusivity']>({
      country: [],
      lyrics_lang: [],
      track_age: [],
    })

    // utils
    function getStateRef(key: keyof UserInfluencerTagsState) {
      switch (key) {
        case 'liked':
          return liked
        case 'hated':
          return hated
        case 'neutral':
          return neutral
        case 'identity':
          return identity
        case 'exclusivity':
          return exclusivity
      }
    }

    // getters
    const COUNTRY = computed(() => identity.value.country[0] ?? 0)

    // FIXME: not sure how this works. Where is the "normalize" function coming from?
    const IS_MENTOR = computed(() => {
      for (const key in identity.value?.influencer_kind ?? [])
        if (key.normalize().toLowerCase() === 'mentor') return true

      return false
    })

    const BADGES = computed(() => {
      return identity.value?.influencer_badge ?? []
    })

    // from tags mixin
    function GET_DISALLOWED(
      category: keyof UserInfluencerTagsState,
      target: TagTypeKey | TagParentTypeKey,
    ) {
      category = category.toLowerCase() as keyof UserInfluencerTagsState
      const allowedKeys = Object.keys(initialState()) as TagTarget[]
      const index = allowedKeys.findIndex((key) => key === category)

      if (category === 'exclusivity') {
        return [] as number[]
      } else if (index !== -1) {
        allowedKeys.splice(index, 1)
        return [
          ...allowedKeys.reduce((accumulator, categoryKey) => {
            const subCat = getStateRef(categoryKey)
            // @ts-expect-error trust
            if (!subCat || !subCat.value || !subCat.value[target])
              return accumulator
            // @ts-expect-error trust
            subCat.value[target].forEach((tagId: number) =>
              accumulator.add(tagId),
            )
            return accumulator
          }, new Set()),
        ] as number[]
      }
      console.warn(
        `[TAGS]: ${category} is not a valid parameter in GET_DISALLOWED`,
      )
      return [] as number[]
    }

    function GET_TAGS(
      category: keyof UserInfluencerTagsState,
      target: TagTypeKey | TagParentTypeKey,
    ) {
      category = category.toLowerCase() as keyof UserInfluencerTagsState
      if (Object.keys(initialState()).includes(category)) {
        const ref = getStateRef(category)
        if (!ref) {
          console.warn(
            `[TAGS]: ${target} is not a valid parameter in GET_CATEGORY`,
          )
          return
        }
        // @ts-expect-error Can't make it
        const out: number[] | undefined = ref.value?.[target]

        if (typeof out !== 'undefined') {
          return out
        } else {
          console.warn(
            `[TAGS]: ${target} is not a valid parameter in GET_CATEGORY`,
          )
        }
      } else {
        console.warn(
          `[TAGS]: ${category} is not a valid parameter in GET_CATEGORY`,
        )
      }

      return []
    }

    // actions
    function SET_TARGET<T extends InfluencerTagsTarget>({
      target,
      patch,
    }: {
      target: T
      patch: Partial<UserInfluencerTagsState[T]>
    }) {
      const availableTargets: InfluencerTagsTarget[] = [
        'liked',
        'hated',
        'neutral',
        'identity',
        'exclusivity',
      ]

      const lowerCaseTarget = target.toLowerCase() as T
      if (availableTargets.includes(lowerCaseTarget)) {
        const ref = getStateRef(lowerCaseTarget)
        if (ref) {
          ref.value = patcherShorthand(initialState(), {
            target: lowerCaseTarget,
            patch,
          })
        }
      } else {
        console.warn(
          `[INFLUENCER TAG] ${lowerCaseTarget} is not a valid target`,
        )
      }
    }

    function RESET() {
      Object.keys(initialState()).forEach((key) => {
        const ref = getStateRef(key as keyof UserInfluencerTagsState)
        if (ref)
          ref.value = initialState()[key as keyof UserInfluencerTagsState]
      })
    }

    async function FETCH(slug?: string) {
      if (!userStore.IS_INF && !userStore.is_staff) return null

      const baseUrl = '/influencer/tags/'
      const url = slug ? `${baseUrl}?slug=${slug}` : baseUrl

      const values = await coreFetch
        .$get<UserInfluencerTagsState>(url)
        .catch((err) => err)

      const keys = [
        'liked',
        'neutral',
        'hated',
        'exclusivity',
      ] as (keyof UserInfluencerTagsState)[]

      keys.forEach((target) => {
        SET_TARGET({
          patch: values[target] ?? {},
          target,
        })
      })

      SET_TARGET({
        patch: {
          ...values.identity,
          influencer_kind: values.identity?.influencer_kind ?? [],
        },
        target: 'identity',
      })

      return values
    }

    async function UPDATE_FROM_PATCH({
      patch,
      influencer_id: influencerId,
    }: {
      patch: DeepPartial<InfluencerTags>
      influencer_id?: number
    }) {
      const baseUrl = '/influencer/tags/'
      const url = influencerId ? `${baseUrl}?id=${influencerId}` : baseUrl

      const patchedData = await coreFetch.$post<InfluencerTags>(url, patch)
      SET_TARGET({ patch: patchedData.identity, target: 'identity' })
      return patchedData
    }

    return {
      // state
      liked,
      hated,
      neutral,
      identity,
      exclusivity,

      // getters
      COUNTRY,
      IS_MENTOR,
      BADGES,
      GET_DISALLOWED,
      GET_TAGS,

      // actions
      SET_TARGET,
      RESET,
      FETCH,
      UPDATE_FROM_PATCH,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserInfluencerTagsStore, import.meta.hot),
  )
}
