import type { CoreFetch } from '~/types/CoreFetch'
import type Decision from '~/types/decision'
import type Submission from '~/types/submission'

export interface InfluencerDuplicateApiResponse {
  duplicates: {
    date_sent: string
    id: number
  }[]
}

export function provideGetDashboardSubmission(coreFetch: CoreFetch) {
  return (submissionId: number) =>
    coreFetch.$get<Submission>(`/submission/dashboard/${submissionId}/`)
}

export function provideGetDashboardSubmissions(coreFetch: CoreFetch) {
  return (offset: number, limit: number) =>
    coreFetch.$get<Submission[]>(`/submission/dashboard/`, {
      params: { offset, limit },
    })
}

export function provideGetDecisions(coreFetch: CoreFetch) {
  return () => coreFetch.$get<Decision[]>('/influencer/influencer/decisions/')
}

export function provideDeduplicateTrack(coreFetch: CoreFetch) {
  return (trackId: number, influencerIds: number[]) =>
    coreFetch.$post<InfluencerDuplicateApiResponse>(
      `/track/track/${trackId}/deduplicate/`,
      {
        influencer_pks: influencerIds,
      },
      { timeout: 2500 },
    )
}
