import type { CoreFetch } from '~/types/CoreFetch'
import type { BackendDraftPricingInfo } from '~/types/draft'

type MaxCampaignCost = { cost: number }

export function provideFetchCartPricingInfo(coreFetch: CoreFetch) {
  return async (): Promise<BackendDraftPricingInfo> =>
    await coreFetch.$get('/wallet/minimum-cart-cost/')
}

export function provideFetchMaxSpent(coreFetch: CoreFetch) {
  return async (): Promise<MaxCampaignCost> =>
    await coreFetch.$get('/submission/campaign/most_expensive_cost/')
}
