import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

const initialState = () => ({
  order_id: 0,
  email: '',
  client_id: '0',
  loadedSdk: false,
})

export type PayinPaymentMethodPaypalState = ReturnType<typeof initialState>

export const usePayinPaymentMethodPaypalStore = defineStore(
  'payinPaymentMethodPaypal',
  () => {
    const order_id = ref(0)
    const email = ref('')
    const client_id = ref('0')
    const loadedSdk = ref(false)

    function getStateRef(key: keyof PayinPaymentMethodPaypalState) {
      switch (key) {
        case 'order_id':
          return order_id
        case 'email':
          return email
        case 'client_id':
          return client_id
        case 'loadedSdk':
          return loadedSdk
      }
    }

    // getters

    const READY_TO_CONFIRM = computed(() => {
      return email.value.length > 0
    })

    // actions
    function SET_SDK_LOAD_STATUS(data: boolean) {
      loadedSdk.value = data
    }

    function SET_CLIENT_ID(newId: string) {
      client_id.value = newId
    }

    function SET_ORDER_ID(newOrderId: number) {
      if (typeof newOrderId === 'number') order_id.value = newOrderId
      else order_id.value = 0
    }

    function SET_EMAIL(newEmail: string) {
      email.value = newEmail
    }

    function RESET() {
      Object.keys(initialState()).forEach((key) => {
        const ref = getStateRef(key as keyof PayinPaymentMethodPaypalState)
        if (ref) {
          ref.value = initialState()[
            key as keyof PayinPaymentMethodPaypalState
          ] as typeof ref.value
        }
      })
    }

    return {
      // state
      order_id,
      email,
      client_id,
      loadedSdk,

      // getters
      READY_TO_CONFIRM,

      // actions
      SET_SDK_LOAD_STATUS,
      SET_CLIENT_ID,
      SET_ORDER_ID,
      SET_EMAIL,
      RESET,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinPaymentMethodPaypalStore, import.meta.hot),
  )
}
