import type { IUserState, UserState } from '~/stores/user'
import type { UserAgencyState } from '~/stores/userAgency'
import type { IUserInfluencerState } from '~/stores/userInfluencer'
import type { Band } from '~/types/band'
import type { CoreFetch } from '~/types/CoreFetch'
import type { Influencer } from '~/types/influencer'
import type { Locale } from '~/types/locale'
import type MediaLinks from '~/types/mediaLinks'
import type { Sentry as SentryTypes } from '~/types/Sentry'
import type { UserKind } from '~/types/UserKind'

type AgencyOnlyReturn = {
  agency_wallet: {
    groovies: string // number in string format
  }
  agency_data: UserAgencyState
}

type InfluencerOnlyReturn = {
  influencer_wallet: {
    groovies: string
  }
  influencer_data: IUserInfluencerState & { media_links: MediaLinks }
}

export type AuthUserBasePayload = {
  google_token?: string | null
  soundcloud_code?: string | null
  soundcloud_token?: string | null
  email: string
  picture: null | string
  is_facebook: boolean
  is_google: boolean
  is_soundcloud: boolean
  email_disabled: boolean
  id: number
  first_name: string
  agency: number
  influencer: null | number
  is_active: boolean
  is_staff: boolean
  lang: Locale
  last_name: string
  is_verified_mail: boolean
  agency_wallet: null | {
    agency: number
    groovies: string
    legal_data: null | LegalData
    user: number
    first_payment_date: null | string
  }
  agency_data: null | {
    id: number
    kind: AgencyKind
    entity: string
    user: number
    latest_campaign_date: null | string
    hidden_influencers: number[]
    last_excel_export_date: false | string
  }
  band_set: number[]
  /**
   * Date in yyyy-mm-ddThh:mm:ssZ string format
   */
  first_payment_date: null | string
}

export type AuthUserReturn<KIND extends UserKind = UserKind> =
  (KIND extends 'band'
    ? AgencyOnlyReturn
    : KIND extends 'influencer'
      ? InfluencerOnlyReturn
      : Record<string, never>) &
    AuthUserBasePayload

export function getUserIsAgencyType(
  payload: AuthUserReturn,
): payload is AuthUserReturn<'band'> {
  // @ts-expect-error Type guard
  return !!payload && !!payload.agency_data && !!payload.agency_wallet
}

export function getUserIsInfluencerType(
  payload: AuthUserReturn,
): payload is AuthUserReturn<'influencer'> {
  // @ts-expect-error Type guard
  return !!payload && !!payload.influencer_data && !!payload.influencer_wallet
}

export function provideGetUser(coreFetch: CoreFetch) {
  return async function (): Promise<{
    user: IUserState
    influencer: null | IUserInfluencerState
    agency: UserAgencyState | null
  }> {
    const userData = await coreFetch.$get<AuthUserReturn>('/auth/user/')
    return transformPayload(userData)
  }
}

export function provideDisconnectUser(
  coreFetch: CoreFetch,
  sentry?: SentryTypes,
) {
  return async (email: string) =>
    await coreFetch
      .$patch<UserState>(`/auth/user/${email}/sso_sc_off/`)
      .catch((error) => {
        sentry?.captureException(error)
      })
}

export function provideDisconnectBand(
  coreFetch: CoreFetch,
  sentry?: SentryTypes,
) {
  return async (bandId: number) =>
    await coreFetch
      .$patch<Band>(`/band/band/${bandId}/sso_sc_off/`)
      .catch((error) => {
        sentry?.captureException(error)
      })
}

export function providePatchBand(coreFetch: CoreFetch) {
  return function (bandId: number, payload: Partial<Band>) {
    return coreFetch.$patch<Band>(`/band/band/${bandId}/`, payload)
  }
}

export function providePatchInfluencer(coreFetch: CoreFetch) {
  return function (influencerId: number, payload: Partial<Influencer>) {
    return coreFetch.$patch<Influencer>(
      `/influencer/influencer/${influencerId}/`,
      payload,
    )
  }
}

export function provideCreateNewBand(coreFetch: CoreFetch) {
  return function (
    payload: Pick<Band, 'name'> & { agency: number; country: number },
  ) {
    return coreFetch.$post<Band>('/band/band/', payload)
  }
}

export function providePatchUserEmailLocale(coreFetch: CoreFetch) {
  return function (locale: Locale, email: string) {
    return coreFetch.$patch(`/auth/user/${email}/lang/`, {
      lang: locale,
    })
  }
}

export function transformPayload(userData: AuthUserReturn<UserKind>) {
  const wallet = getUserIsAgencyType(userData)
    ? userData.agency_wallet
    : getUserIsInfluencerType(userData)
      ? userData.influencer_wallet
      : null
  const user: IUserState = {
    email: userData.email,
    first_name: userData.first_name,
    first_payment_date: userData.first_payment_date,
    grooviz: wallet ? Number(wallet.groovies) : 0,
    id: userData.id,
    is_active: userData.is_active,
    is_facebook: userData.is_facebook,
    is_google: userData.is_google,
    google_token: userData.google_token,
    is_soundcloud: userData.is_soundcloud,
    soundcloud_code: userData.soundcloud_code,
    soundcloud_token: userData.soundcloud_token,
    is_staff: userData.is_staff,
    is_verified_mail: userData.is_verified_mail,
    lang: userData.lang,
    last_name: userData.last_name,
    picture: userData.picture,
    submissions: [0, 0, 0],
    ...(getUserIsInfluencerType(userData)
      ? { media_links: userData.influencer_data.media_links }
      : {}),
  }
  return {
    user,
    influencer: getUserIsInfluencerType(userData)
      ? userData.influencer_data
      : null,
    agency: getUserIsAgencyType(userData) ? userData.agency_data : null,
  }
}
