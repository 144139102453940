import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersRecommendationsStore } from './influencersRecommendations'
import { useUserStore } from './user'

import { configToQs } from '~/helpers/configToQs'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import { provideGetCuratorBySlug } from '~/api-core/Curators/GetCurator'

import type { InfluencersDuplicatesState } from '~/stores/influencersDuplicates'
import type { InfluencersInteractionsState } from '~/stores/influencersInteractions'
import type { InfluencersRecommendationsState } from '~/stores/influencersRecommendations'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'

export interface FetchConfig {
  offset: number
  limit: number
  noFollow?: boolean
  noFetchRecommendation?: boolean
}
export type InfluencersApiResponse = PaginatedApiResponse<StatsV3Influencer>

const initialState = () => {
  return {
    list: [] as StatsV3Influencer[],
    defaultConfig: {
      offset: 0,
      limit: 128,
    } as FetchConfig,
  }
}

const state = initialState

type IInfluencersState = ReturnType<typeof state>

export interface InfluencersState extends IInfluencersState {
  duplicates: InfluencersDuplicatesState
  recommendation: InfluencersRecommendationsState
  interactions: InfluencersInteractionsState
}

export const useInfluencersStore = defineStore('influencers', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const influencersRecommendationsStore =
    useInfluencersRecommendationsStore($pinia)

  const list = ref(state().list)
  const defaultConfig = ref(state().defaultConfig)

  const IDS = computed(() => {
    return list.value.map((e) => e.id)
  })
  const ALL_IDS = computed((): number[] => {
    return influencersRecommendationsStore.ranks.length
      ? influencersRecommendationsStore.ranks
      : IDS.value
  })
  const IDS_IN_DB_BY_RANK = computed((): number[] => {
    if (!influencersRecommendationsStore.ranks.length) return IDS.value

    const idsInDb = IDS.value
    const ranked = influencersRecommendationsStore.ranks.filter((id) =>
      idsInDb.includes(id),
    )
    const notRankedButInDb = idsInDb.filter((id) => !ranked.includes(id))
    return [...ranked, ...notRankedButInDb]
  })

  function GET_BY_ID(id: number): StatsV3Influencer | undefined {
    return list.value.find((e) => e.id === id)
  }

  function GET_BY_IDS(ids: number[]): StatsV3Influencer[] {
    return list.value
      .filter((e) => ids.includes(e.id))
      .sort((a, b) => {
        return ids.indexOf(a.id) - ids.indexOf(b.id)
      })
  }

  function GET_BY_IDS_SORTED(ids: number[]): StatsV3Influencer[] {
    return GET_BY_IDS(ids).sort((a, b) => {
      return ids.indexOf(a.id) - ids.indexOf(b.id)
    })
  }

  function GET_BY_SLUG(slug: string): StatsV3Influencer | undefined {
    return list.value.find((e) => e.slug === slug)
  }

  function ID_EXISTS(influencerId: number) {
    return list.value.some((influencer) => influencer.id === influencerId)
  }

  function SLUG_EXISTS(influencerSlug: string) {
    return list.value.some((e) => {
      return e.slug === influencerSlug
    })
  }

  function SET_INFLUENCER_ARRAY(influencers: StatsV3Influencer[]) {
    list.value = influencers
  }
  function ADD_INFLUENCER_ARRAY(influencers: StatsV3Influencer[]) {
    const copy = list.value.filter((influencer) => {
      return influencers.every(
        (newInfluencer) => influencer.id !== newInfluencer.id,
      )
    })
    list.value = copy.concat(influencers)
  }
  function REMOVE_INFLUENCER_AT_INDEX(index: number) {
    list.value.splice(index, 1)
  }
  function REMOVE_INFLUENCERS_IDS(ids: number[]) {
    list.value = list.value.filter((e) => !ids.includes(e.id))
  }
  function JEST_ONLY_MOD_CONFIG(newConfig: FetchConfig) {
    defaultConfig.value = newConfig
  }
  function RESET() {
    list.value = initialState().list
    defaultConfig.value = initialState().defaultConfig
  }
  /**
   * Fetches influencers from the reco service and sets them in the store.
   * Note that error catching is handled in 'pages/draft/[id]/influencers/loading.vue' where this is used.
   */
  async function FETCH_FROM_RECO(): Promise<void> {
    const influencersRecommendationsStore =
      useInfluencersRecommendationsStore($pinia)

    await influencersRecommendationsStore.FETCH_RECOMMENDATION()
    const influencerIds = influencersRecommendationsStore.ranks
    RESET()

    const fetchResponse = await FETCH_SET_AXIOS(influencerIds)
    return HANDLE_INFLUENCER_BATCH({
      response: fetchResponse,
      config: {
        noFollow: true,
        noFetchRecommendation: true,
      } as FetchConfig,
    })
  }
  function FETCH_FROM_BACKEND(
    dataConfig: Partial<FetchConfig> = {},
  ): Promise<void> {
    const config = { ...defaultConfig.value, ...dataConfig }

    return coreFetch
      .$get<InfluencersApiResponse>(
        `/influencer/statsv3/?limit=${config.limit}&offset=${config.offset}`,
      )
      .then(async (response) => {
        config.offset += config.limit
        return await HANDLE_INFLUENCER_BATCH({ config, response })
      })
  }
  function FETCH_SET_AXIOS(
    influencerIdArray: (string | number)[],
    fetchHidden?: boolean,
  ): Promise<InfluencersApiResponse> {
    // TODO: leave this comment here for now, we might need it later when migrating these pages
    // const route = useRoute()
    // const fetchHidden =
    //   route.path.includes('/band/dashboard') ||
    //   route.path.includes('/band/edit/track') ||
    //   route.path.includes('/band/signup/referral/')
    //     ? true
    //     : undefined
    const maxIdCount = 128
    const fetching = influencerIdArray
      .map((influencerId) => Number(influencerId))
      .filter((influencerId) => {
        return !list.value?.some(
          (influencer: StatsV3Influencer) => influencer.id === influencerId,
        )
      })

    if (!fetching.length) {
      return Promise.resolve({
        count: 0,
        next: null,
        previous: null,
        results: [] as StatsV3Influencer[],
      })
    }

    return Promise.all(
      fetching
        .reduce(
          (accumulator, influencerId) => {
            const lastMember: number[] = accumulator[accumulator.length - 1]

            if (lastMember.length >= maxIdCount)
              accumulator.push([] as number[])

            accumulator[accumulator.length - 1].push(influencerId)
            return accumulator
          },
          [[]] as number[][],
        )
        .map((idArray) => {
          return coreFetch.$get<InfluencersApiResponse>(
            `/influencer/statsv3/?${configToQs({
              unfiltered: fetchHidden?.toString(),
              bulk: idArray.toString(),
            })}`,
          )
        }),
    ).then((responses) => {
      const response = responses.reduce(
        (accumulator, response) => {
          accumulator.results.push(...response.results)
          accumulator.count += response.count
          return accumulator
        },
        {
          count: 0,
          next: null,
          previous: null,
          results: [] as StatsV3Influencer[],
        },
      )

      response.results.sort((a, b) => {
        return fetching.indexOf(b.id) - fetching.indexOf(a.id)
      })
      return response
    })
  }
  /**
   * Is a wrapper around "FETCH_SET_AXIOS" - we can probably move that function here.
   * @param influencerIdArray - The influencer ids to fetch.
   * @param fetchHidden - Whether or not to fetch hidden influencers.
   * @returns Influencer api response.
   *
   */
  function FETCH_SET(
    influencerIdArray: (string | number)[],
    fetchHidden?: boolean,
  ): Promise<number[]> {
    return FETCH_SET_AXIOS(influencerIdArray, fetchHidden)
      .then(async (response: InfluencersApiResponse) => {
        await HANDLE_INFLUENCER_BATCH({
          response,
          config: { ...defaultConfig.value, noFollow: true },
        })
        return response.results.map((e) => e.id)
      })
      .catch(() => {
        return []
      })
  }
  function HANDLE_INFLUENCER_BATCH({
    response,
    config,
  }: {
    response: Partial<InfluencersApiResponse>
    config: Partial<FetchConfig>
  }) {
    const pinia = $pinia
    const userStore = useUserStore(pinia)
    const influencersRecommendationsStore =
      useInfluencersRecommendationsStore(pinia)

    const { next, results } = response
    const ids = [...new Set(results?.map((e) => e.id))]

    const batch = ids
      .filter((influencerId) => {
        // We can guaranty the results here
        const rawInfluencer = results?.find(
          (e) => e.id === influencerId,
        ) as StatsV3Influencer

        return (
          !IDS.value.includes(rawInfluencer.id) &&
          (!rawInfluencer.is_scout || userStore.email === 'agency@groover.co')
        )
      })
      .reduce((accumulator, influencerId: number) => {
        const influencer = results?.find((influencer: StatsV3Influencer) => {
          return influencer.id === influencerId
        })

        // We can guarantee the result here as well
        accumulator.push(influencer as StatsV3Influencer)
        return accumulator
      }, [] as StatsV3Influencer[])

    return influencersRecommendationsStore
      .FETCH_FROM_INFLUENCER_IDS(
        config.noFetchRecommendation !== true ? ids : [],
      )
      .finally(() => {
        if (batch.length) ADD_INFLUENCER_ARRAY(batch)

        if (import.meta.client && config.noFollow !== true && next)
          return FETCH_FROM_BACKEND(config)
        else return true
      })
  }
  function FETCH_SLUG(slug: string): Promise<Influencer> {
    const getCuratorBySlug = provideGetCuratorBySlug(coreFetch)
    return getCuratorBySlug(slug)
  }
  function REMOVE_ONE({ slug, id }: { slug?: string; id?: number }) {
    const fns = {
      slug(e: StatsV3Influencer) {
        return e.slug === slug
      },
      id(e: StatsV3Influencer) {
        return e.id === id
      },
    }
    const index = list.value.findIndex(fns[slug ? 'slug' : 'id'])

    if (index >= 0) REMOVE_INFLUENCER_AT_INDEX(index)
  }
  function REMOVE_INVISIBLE(): void {
    const invisibleInfluencerIds = list.value
      .filter((influencer) => !influencer.visible)
      .map((influencer) => influencer.id)

    REMOVE_INFLUENCERS_IDS(invisibleInfluencerIds)
  }

  return {
    // state
    list,
    defaultConfig,

    // getters
    IDS,
    ALL_IDS,
    IDS_IN_DB_BY_RANK,

    // actions
    GET_BY_ID,
    GET_BY_IDS,
    GET_BY_IDS_SORTED,
    GET_BY_SLUG,
    ID_EXISTS,
    SLUG_EXISTS,
    SET_INFLUENCER_ARRAY,
    ADD_INFLUENCER_ARRAY,
    REMOVE_INFLUENCER_AT_INDEX,
    REMOVE_INFLUENCERS_IDS,
    JEST_ONLY_MOD_CONFIG,
    RESET,
    FETCH_FROM_RECO,
    FETCH_FROM_BACKEND,
    FETCH_SET_AXIOS,
    FETCH_SET,
    HANDLE_INFLUENCER_BATCH,
    FETCH_SLUG,
    REMOVE_ONE,
    REMOVE_INVISIBLE,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useInfluencersStore, import.meta.hot))
