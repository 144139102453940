// See https://github.com/nuxt-community/sentry-module/issues/530
import * as Sentry from '@sentry/vue'

import { useUserStore } from '~/stores/user'

import { $isFetchError } from '~/helpers/fetch'

export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.client || import.meta.env.TEST) {
    return {
      provide: {
        sentry: undefined as typeof Sentry | undefined,
      },
    }
  }

  const router = useRouter()
  const {
    public: { SENTRY_DSN, SENTRY_ENABLED, SENTRY_RELEASE_ID, ENV_NAME },
  } = useRuntimeConfig()
  const userStore = useUserStore()

  // If no sentry DSN set, ignore and warn in the console
  if (!SENTRY_DSN || SENTRY_ENABLED !== 'true') {
    if (!SENTRY_DSN) {
      console.warn(
        'Sentry DSN not set, skipping client side Sentry initialization',
      )
    }
    return {
      provide: {
        sentry: undefined as typeof Sentry | undefined,
      },
    }
  }

  const isProduction = ENV_NAME === 'prod'

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: SENTRY_DSN,
    environment: ENV_NAME,
    release: SENTRY_RELEASE_ID,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: isProduction ? 0.01 : 0.1,
    profilesSampleRate: isProduction ? 0.01 : 0.1,
    hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
    replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['FetchError'],
    beforeSend(event, { originalException }) {
      if ($isFetchError(originalException)) return null
      return event
    },
  })

  Sentry.setUser({
    email: userStore.email,
    id: userStore.id,
  })

  return {
    provide: {
      sentry: Sentry as typeof Sentry | undefined,
    },
  }
})
