/* eslint-disable camelcase */
import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftTrackTagsStore } from './draftTrackTags'
import { useMiscTrackStore } from './miscTrack'

import {
  provideCreateTrack,
  provideGetTrack,
  providePatchTrack,
} from '~/api-core/Draft/DraftTrack'

import type { TrackTagsState } from './draftTrackTags'
import type { Track, TrackSource } from '~/types/track'

export const draftTrackInitialState = () => ({
  id: 0,
  band: 0,
  info: '',
  link: '',
  ep_link: '',
  event: '',
  secondary_link: '',
  name: '',
  release_date: null as null | string,
  is_demo: false,
  is_exclu: false,
  is_public: false,
  created_from_profile: true,
  generated_from: 'profile' as TrackSource,
  is_archived: false,
  is_boosted: false,
  album_cover: null as null | string,
  album_link: null as null | string,
  files_update_timestamp: null as null | string,
  extra_pictures: [] as string[],
  press_release: null as null | string,
  track_file: null as null | string,
  in_previous_campaign: false,
})

const state = draftTrackInitialState()

export type IDraftTrackState = ReturnType<typeof draftTrackInitialState>

export interface DraftTrackState extends IDraftTrackState {
  tags: TrackTagsState
}

export const useDraftTrackStore = defineStore('draftTrack', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const draftTrackTags = useDraftTrackTagsStore($pinia)
  const draftTrackTagsStore = useDraftTrackTagsStore($pinia)
  const miscTrackStore = useMiscTrackStore($pinia)

  const id = ref(state.id)
  const band = ref(state.band)

  const name = ref(state.name)
  const link = ref(state.link)
  const secondary_link = ref(state.secondary_link)
  const ep_link = ref(state.ep_link)

  const release_date = ref(state.release_date)

  const info = ref(state.info)
  const event = ref(state.event)
  const is_demo = ref(state.is_demo)
  const is_exclu = ref(state.is_exclu)
  const is_public = ref(state.is_public)
  const created_from_profile = ref(state.created_from_profile)
  const generated_from = ref(state.generated_from)
  const is_archived = ref(state.is_archived)
  const is_boosted = ref(state.is_boosted)
  const album_cover = ref(state.album_cover)
  const album_link = ref(state.album_link)
  const files_update_timestamp = ref(state.files_update_timestamp)
  const extra_pictures = ref(state.extra_pictures)
  const press_release = ref(state.press_release)
  const track_file = ref(state.track_file)
  const in_previous_campaign = ref(state.in_previous_campaign)

  function getStateRef(key: keyof DraftTrackState) {
    switch (key) {
      case 'id':
        return id
      case 'band':
        return band
      case 'name':
        return name
      case 'link':
        return link
      case 'secondary_link':
        return secondary_link
      case 'ep_link':
        return ep_link
      case 'release_date':
        return release_date
      case 'info':
        return info
      case 'event':
        return event
      case 'is_demo':
        return is_demo
      case 'is_exclu':
        return is_exclu
      case 'is_public':
        return is_public
      case 'created_from_profile':
        return created_from_profile
      case 'generated_from':
        return generated_from
      case 'is_archived':
        return is_archived
      case 'is_boosted':
        return is_boosted
      case 'album_cover':
        return album_cover
      case 'album_link':
        return album_link
      case 'files_update_timestamp':
        return files_update_timestamp
      case 'extra_pictures':
        return extra_pictures
      case 'press_release':
        return press_release
      case 'track_file':
        return track_file
      case 'in_previous_campaign':
        return in_previous_campaign
    }
  }
  function SET(patch: Partial<DraftTrackState>) {
    Object.keys(patch).forEach((key) => {
      const keyName = key as keyof DraftTrackState
      const val = patch[keyName] as DraftTrackState[typeof keyName]

      if (key === 'tags') draftTrackTags.SET(val)

      const stateRef = getStateRef(keyName)
      if (stateRef === undefined) return

      stateRef.value = val
    })
  }

  async function FETCH(trackId: number): Promise<Track | null> {
    if (!trackId) return null

    const [track] = await Promise.all([
      GET(trackId),
      draftTrackTagsStore.FETCH(trackId),
    ]).catch(() => [null])

    if (track) {
      // convert is_boosted to boolean
      track.is_boosted = Boolean(track.is_boosted)
      SET(track as DraftTrackState)

      return track
    }

    return null
  }

  async function GET(trackId: number) {
    const getTrack = provideGetTrack(coreFetch)
    return await getTrack(trackId).catch(() => null)
  }

  function UPDATE_ON_SERVER(
    track: Partial<Record<keyof DraftTrackState, any>>,
  ) {
    if (track.release_date !== undefined) {
      track.release_date = track.release_date?.length
        ? track.release_date
        : null
    }

    if (track.id) {
      return SERVER_PATCH(track)
    } else {
      if (miscTrackStore.isTrackBeingCreated) return Promise.resolve(undefined)

      return SERVER_POST(track)
    }
  }

  function SERVER_POST(track: Partial<Track>): Promise<Track> {
    miscTrackStore.SET_IS_TRACK_BEING_CREATED(true)

    const createTrack = provideCreateTrack(coreFetch)
    return createTrack(track)
      .then((track) => {
        SET(track)
        return track
      })
      .finally(() => miscTrackStore.SET_IS_TRACK_BEING_CREATED(false))
  }

  function SERVER_PATCH(
    track: Partial<Record<keyof DraftTrackState, any>>,
  ): Promise<Track> {
    const patchTrack = providePatchTrack(coreFetch)
    return patchTrack(track.id, track).then((track) => {
      if (track.release_date === null) track.release_date = ''

      SET(track as DraftTrackState)
      return track
    })
  }

  function RESET() {
    SET(state)
  }

  return {
    // state
    id,
    band,
    name,
    link,
    secondary_link,
    ep_link,
    release_date,
    info,
    event,
    is_demo,
    is_exclu,
    is_public,
    created_from_profile,
    generated_from,
    is_archived,
    is_boosted,
    album_cover,
    album_link,
    files_update_timestamp,
    extra_pictures,
    press_release,
    track_file,
    in_previous_campaign,

    // actions
    SET,
    FETCH,
    GET,
    UPDATE_ON_SERVER,
    SERVER_POST,
    SERVER_PATCH,
    RESET,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftTrackStore, import.meta.hot))
