import type { CoreFetch } from '~/types/CoreFetch'
import type Track from '~/types/track'

export function provideCreateTrackForDraft(coreFetch: CoreFetch) {
  return (track_data: {
    name: string
    band: number
    user: number
    spotify_id?: string
    soundcloud_id?: string
  }): Promise<Track> =>
    coreFetch.$post<Track>('/track/track/create_for_draft/', track_data)
}
