import { acceptHMRUpdate, defineStore } from 'pinia'

import { usePayinPaymentMethodStore } from './payinPaymentMethod'

import type { AvailablePaymentMethod } from './payinPaymentMethod'
import type { Card } from '~/types/stripe'

export type PayinPaymentMethodCardsState = Pick<
  ReturnType<typeof usePayinPaymentMethodCardsStore>,
  'available' | 'selected'
>

export const usePayinPaymentMethodCardsStore = defineStore(
  'payinPaymentMethodCards',
  () => {
    const { coreFetch } = useProvideCoreFetch()

    const available = ref<Card[]>([])
    const selected = ref<Card['id'] | null>(null)

    // actions
    function REMOVE_ID_FROM_AVAILABLE(id: Card['id'] | null): void {
      if (id !== null) {
        const index = available.value.findIndex((e) => e.id === id)

        if (index !== -1) available.value.splice(index, 1)
      }
    }

    async function FETCH() {
      try {
        const cards = await coreFetch.$get<Card[]>('/stripe/payment-methods/')

        SET_AVAILABLE(cards)
        return cards
      } catch (_) {
        // mute error but return empty array so when used by `useAsyncData` it isn't called again on the client side
        return []
      }
    }

    async function DELETE_FROM_ID(id: Card['id'] | null) {
      if (!id) return
      const payinPaymentMethodStore = usePayinPaymentMethodStore()

      try {
        await coreFetch.$delete(`/stripe/payment-methods/${id}/`)

        if (id === selected.value) SET_SELECTED(null)

        REMOVE_ID_FROM_AVAILABLE(id)
        if (!available.value.length) {
          const newPaymentType: AvailablePaymentMethod = 'create_card'

          SET_SELECTED(null)
          payinPaymentMethodStore.SET_SELECTED(newPaymentType)
        }
      } catch (_) {
        // mute error
      }
    }

    function SET_AVAILABLE(cards: Card[]) {
      available.value = cards
    }

    function SET_SELECTED(cardId: Card['id'] | null) {
      selected.value = cardId
    }

    return {
      // state
      available,
      selected,

      // actions
      FETCH,
      DELETE_FROM_ID,
      REMOVE_ID_FROM_AVAILABLE,
      SET_SELECTED,
      SET_AVAILABLE,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinPaymentMethodCardsStore, import.meta.hot),
  )
}
