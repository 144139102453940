import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftStore } from './draft'
import { useDraftPricingStore } from './draftPricing'
import { usePayinBillingInfosLegalDataStore } from './payinBillingInfosLegalData'
import { usePayinPacksStore } from './payinPacks'
import { usePayinPaymentMethodStore } from './payinPaymentMethod'
import { usePayinPaymentMethodCardsStore } from './payinPaymentMethodCards'
import { usePayinPaymentMethodPaypalStore } from './payinPaymentMethodPaypal'
import { useUserStore } from './user'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { lockSafeguard } from '~/helpers/payin/lockSafeguard'

import type { CreateOrderRequestBody } from '@paypal/paypal-js'
import type Pack from '~/types/pack'

export const acceptablePayinOrigins = [
  'sendtrack',
  'unitary_pack',
  'pack',
] as const
export type AcceptablePayinOrigins = (typeof acceptablePayinOrigins)[number]

export type PayinState = Pick<
  ReturnType<typeof usePayinStore>,
  'processingPayment' | 'currentOrigin' | 'unitaryPackCost'
>

type EventData = {
  currency: string
  value: number
  content_ids: (AcceptablePayinOrigins | '')[]
  content_type: string
  first_payment: string | null
  is_pack: boolean
  pack_name?: string
  is_shop: boolean
}

type LooseEventData = {
  $revenue: number
  type: AcceptablePayinOrigins | ''
  first_payment: string | null
  is_pack: boolean
  pack_name?: string
}

export const usePayinStore = defineStore('payin', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const draftStore = useDraftStore($pinia)
  const payinPacksStore = usePayinPacksStore($pinia)
  const draftPricingStore = useDraftPricingStore($pinia)
  const payinPaymentMethodStore = usePayinPaymentMethodStore($pinia)
  const payinPaymentMethodPaypalStore = usePayinPaymentMethodPaypalStore($pinia)
  const payinPaymentMethodCardsStore = usePayinPaymentMethodCardsStore($pinia)
  const payinBillingInfosLegalDataStore =
    usePayinBillingInfosLegalDataStore($pinia)

  const processingPayment = ref(false)
  const currentOrigin = ref<AcceptablePayinOrigins | ''>('')
  const unitaryPackCost = ref(0)

  const CAN_PAY = computed<boolean>(() => {
    if (!processingPayment.value) {
      if (payinPaymentMethodStore.selected === 'paypal') {
        return payinPaymentMethodPaypalStore.READY_TO_CONFIRM
      } else {
        return Boolean(
          payinPaymentMethodStore.selected === 'card' &&
            payinPaymentMethodCardsStore.selected !== null,
        )
      }
    } else {
      return false
    }
  })

  const PURCHASE_TYPE = computed<AcceptablePayinOrigins | ''>(() => {
    const hasValidPurchaseType = acceptablePayinOrigins.includes(
      currentOrigin.value as AcceptablePayinOrigins,
    )
    const requirements = {
      sendtrack: userStore.IS_BAND,
      unitary_pack:
        unitaryPackCost.value >= 10 && unitaryPackCost.value <= 10000,
      pack: true,
    } as const

    return hasValidPurchaseType &&
      requirements[currentOrigin.value as AcceptablePayinOrigins] !== false
      ? currentOrigin.value
      : ''
  })

  const MISSING_CREDITS = computed<number>(() => {
    const type = PURCHASE_TYPE.value

    if (type === 'sendtrack') return draftPricingStore.MISSING
    else if (type === 'unitary_pack') return unitaryPackCost.value
    else if (type === 'pack')
      return payinPacksStore.SELECTED_PACK_OBJ?.cost ?? 0
    else return 0
  })

  const PURCHASE_AMOUNT = computed<number>(() => {
    const type = PURCHASE_TYPE.value

    if (type === 'sendtrack') return draftPricingStore.ADJUSTED_MISSING
    if (type === 'unitary_pack') return unitaryPackCost.value
    if (type === 'pack' && payinPacksStore.IS_SELECTED) return 0
    return 0
  })

  const FINAL_PURCHASE_AMOUNT = computed<number>(() => {
    if (!payinPacksStore.IS_SELECTED) return PURCHASE_AMOUNT.value
    else return payinPacksStore.SELECTED_PACK_OBJ?.cost as number
  })

  const FINAL_PURCHASE_AMOUNT_CENTS = computed<number>(() => {
    // Number.EPSILON prevents rounding errors
    return Math.round((FINAL_PURCHASE_AMOUNT.value + Number.EPSILON) * 100)
  })

  const FINAL_CREDITS_PURCHASE_AMOUNT = computed<number>(() => {
    if (payinPacksStore.IS_SELECTED)
      return payinPacksStore.SELECTED_PACK_OBJ?.groovies as number

    if (PURCHASE_TYPE.value === 'sendtrack')
      return draftPricingStore.ADJUSTED_MISSING
    else return MISSING_CREDITS.value
  })

  const STANDARD_EVENT_DATA = computed(() => {
    return {
      currency: 'EUR',
      value: FINAL_PURCHASE_AMOUNT.value,
      content_ids: [PURCHASE_TYPE.value],
      content_type: 'product',
      first_payment: userStore.first_payment_date,
      is_pack: payinPacksStore.IS_SELECTED,
      pack_name: payinPacksStore.IS_SELECTED
        ? payinPacksStore.SELECTED_PACK_OBJ?.name
        : undefined,
      is_shop: false,
    }
  })

  const LOOSE_EVENT_DATA = computed(() => {
    return {
      $revenue: FINAL_PURCHASE_AMOUNT.value,
      type: PURCHASE_TYPE.value,
      first_payment: userStore.first_payment_date,
      is_pack: payinPacksStore.IS_SELECTED,
      pack_name: payinPacksStore.IS_SELECTED
        ? payinPacksStore.SELECTED_PACK_OBJ?.name
        : undefined,
    }
  })

  const CAMPAIGN_COST = computed<number>(() => {
    if (currentOrigin.value === 'sendtrack') {
      const cost = MISSING_CREDITS.value || 0
      return Math.max(cost, 10)
    } else {
      return 10
    }
  })

  const AVAILABLE_PACKS = computed<Pack[]>(() => {
    const ret = []

    for (let i = payinPacksStore.raw_available.length - 1; i >= 0; i--) {
      if (payinPacksStore.raw_available[i].cost > CAMPAIGN_COST.value * 1.15)
        ret.push(payinPacksStore.raw_available[i])
    }

    return ret
  })

  const BEST_PACK = computed<Pack | undefined>(() => {
    // AB Test 'pack-payment-page-multiplier' cells 'increased-multiplier' and 'bigger-spenders'
    let multiplier = 1.33 // product magic number

    for (const pack of payinPacksStore.raw_available) {
      if (CAMPAIGN_COST.value * multiplier < pack.cost) return pack

      multiplier -= 0.06 // product magic number
    }
  })

  const PAYMENT_DATA = computed<PaymentData<AcceptablePayinOrigins>>(() => {
    const draftId = draftStore.id
    const transactionType: AcceptablePayinOrigins | '' = PURCHASE_TYPE.value
    const ret = {} as PaymentData<AcceptablePayinOrigins>

    if (transactionType !== '') {
      if (payinPacksStore.IS_SELECTED) {
        ret.kind = 'pack'
        ret.pack = payinPacksStore.selected
        ret.draft =
          draftId > 0 && transactionType === 'sendtrack' ? draftId : undefined
      } else if (draftId > 0 && transactionType === 'sendtrack') {
        ret.kind = transactionType
        ret.draft = draftId
      } else {
        ret.kind = transactionType
        ret.value = FINAL_PURCHASE_AMOUNT.value
      }
      ret.legal_data = payinBillingInfosLegalDataStore.id
      ret.user = userStore.id
    }
    return ret
  })

  const PAYMENT_DATA_PAYPAL = computed<CreateOrderRequestBody>(() => {
    return {
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            value: (FINAL_PURCHASE_AMOUNT.value + 1.5).toString(),
            currency_code: 'EUR',
          },
          custom_id: payinPaymentMethodPaypalStore.order_id.toString(),
          reference_id: 'reference',
        },
      ],
    }
  })

  // actions
  function SET_CURRENT_ORIGIN(newOrigin: AcceptablePayinOrigins) {
    if (acceptablePayinOrigins.includes(newOrigin))
      currentOrigin.value = newOrigin
  }

  function SET_PROCESSING_STATUS(boolean: boolean) {
    if (typeof boolean === 'boolean') processingPayment.value = boolean
  }

  function SET_UNITARY_PACK_COST(amount: number | string) {
    const type = typeof amount

    if (type === 'string' || type === 'number') {
      const converted = Number(amount)

      if (!isNaN(converted) && converted >= 0)
        unitaryPackCost.value = Number(amount)
    }
  }

  function CREATE_CAMPAIGN({
    draft,
    invoice,
  }: {
    draft: number
    invoice: boolean
  }) {
    return coreFetch.$post<{ groovies: string; id: number }>(
      '/submission/campaign/',
      {
        draft,
        invoice,
      },
    )
  }

  function RESET() {
    currentOrigin.value = '' as AcceptablePayinOrigins
    processingPayment.value = false
    unitaryPackCost.value = 0
  }

  function PREFLIGHT_ROUTINE({ origin }: { origin: string }) {
    const url = '/paypal-v2/payment-context/'
    const payload = {
      user: userStore.id,
      legal_data: payinBillingInfosLegalDataStore.id,
      value:
        PURCHASE_TYPE.value === 'unitary_pack'
          ? FINAL_CREDITS_PURCHASE_AMOUNT.value
          : undefined,
      draft:
        draftStore.id &&
        PURCHASE_TYPE.value === 'sendtrack' &&
        !payinPacksStore.selected
          ? draftStore.id
          : undefined,
      pack: payinPacksStore.selected || undefined,
      kind: PURCHASE_TYPE.value,
      origin: import.meta.client
        ? (origin ?? window.location.pathname)
        : 'server_side',
    }

    return new Promise((resolve, reject) => {
      lockSafeguard<{ cost: string; groovies: string; id: number }>(
        coreFetch,
        coreFetch.$post(url, payload),
        payload,
        url,
      )
        .then((contextData) => {
          const typeSafeContext = {
            cost: parseFloat(contextData.cost),
            groovies: parseFloat(contextData.groovies),
          } as const
          payinPaymentMethodPaypalStore.SET_ORDER_ID(contextData.id)
          resolve(typeSafeContext)
        })
        .catch(reject)
    })
  }

  async function VALIDATE_TRANSACTION({
    draft,
    invoice = false,
  }: {
    draft?: number
    invoice: boolean
  }) {
    SET_PROCESSING_STATUS(true)

    const response = await CREATE_CAMPAIGN({
      draft: draft || draftStore.id,
      invoice,
    })
    userStore.SET_GROOVIZ(parseFloat(response.groovies))
    return response
  }

  return {
    // state
    processingPayment,
    currentOrigin,
    unitaryPackCost,

    // getters
    CAN_PAY,
    PURCHASE_TYPE,
    MISSING_CREDITS,
    PURCHASE_AMOUNT,
    FINAL_PURCHASE_AMOUNT,
    FINAL_PURCHASE_AMOUNT_CENTS,
    FINAL_CREDITS_PURCHASE_AMOUNT,
    STANDARD_EVENT_DATA,
    LOOSE_EVENT_DATA,
    CAMPAIGN_COST,
    AVAILABLE_PACKS,
    BEST_PACK,
    PAYMENT_DATA,
    PAYMENT_DATA_PAYPAL,

    // actions
    SET_CURRENT_ORIGIN,
    SET_PROCESSING_STATUS,
    SET_UNITARY_PACK_COST,
    RESET,
    CREATE_CAMPAIGN,
    VALIDATE_TRANSACTION,
    PREFLIGHT_ROUTINE,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePayinStore, import.meta.hot))
