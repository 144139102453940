import { acceptHMRUpdate, defineStore } from 'pinia'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import Tag from '~/entities/tag'
import TagParent from '~/entities/tagParent'
import TagType from '~/entities/tagType'

export type TagState = Pick<
  ReturnType<typeof useTagStore>,
  'parents' | 'tags' | 'types'
>

// TODO rewrite this shitty type usage lmfao
type RawTag = ConstructorParameters<typeof Tag>[0]
type RawTagParent = ConstructorParameters<typeof TagParent>[0]
type RawTagType = ConstructorParameters<typeof TagType>[0]

export type ApiFetchTagResponse = {
  tags: RawTag[]
  parents: RawTagParent[]
  types: RawTagType[]
}

export const useTagStore = defineStore('tag', () => {
  const { coreFetch } = useProvideCoreFetch()

  const parents = ref<TagParent[]>([])
  const tags = ref<Tag[]>([])
  const types = ref<TagType[]>([])

  function RESET() {
    parents.value = []
    tags.value = []
    types.value = []
  }

  function SET(patch: ApiFetchTagResponse) {
    parents.value = patch.parents.map((e) => new TagParent(e))
    tags.value = patch.tags.map((e) => new Tag(e))
    types.value = patch.types.map((e) => new TagType(e))
  }

  function FETCH() {
    if (types.value.length && parents.value.length && tags.value.length)
      return Promise.resolve()

    return coreFetch
      .$get<ApiFetchTagResponse>('/tag/meta/')
      .then((data) => {
        SET(data)
      })
      .catch(/** mute error */)
  }

  function GET_PARENT_FROM_ID(parentId: number): TagParent | undefined {
    return parents.value.find((parent) => parentId === parent.id)
  }

  function GET_PARENT_BY_IDS(parentIds: number[]): TagParent[] {
    return parents.value.filter(
      (parent) => parentIds.includes(parent.id) && parent.id !== null,
    )
  }

  function GET_TAGS_FROM_PARENT_ID(parentId: number): Tag[] {
    return tags.value.filter((tag) => tag.parent_id === parentId)
  }

  function FIND_PARENTS_WITH_NAME(tagParentName: string): TagParent[] {
    return parents.value.filter((parent) => parent.name === tagParentName)
  }

  function GET_PARENTS_BY_TAG_IDS(tagIds: number[]): TagParent[] {
    return GET_PARENT_BY_IDS(
      GET_TAGS_FROM_IDS(tagIds).map((tag: Tag) => tag.parent_id),
    )
  }

  function GET_TAG_FROM_ID(tagId: number): Tag | undefined {
    return tags.value.find((tag: Tag) => tag.id === tagId)
  }

  function GET_TAGS_FROM_IDS(tagIds: number[]): Tag[] {
    return tags.value.filter((tag: Tag) => tagIds.includes(tag.id))
  }

  function GET_TAGS_WITH_NAME(tagName: string): Tag[] {
    return tags.value.filter((tag: Tag) => tag.name === tagName)
  }

  function GET_TAG_BY_NAME_FROM_TYPE(
    tagName: string,
    tagTypeName: string,
  ): Tag | undefined {
    const tagType = GET_TAG_TYPE_FROM_NAME(tagTypeName)
    if (!tagType) return

    const candidates = GET_TAGS_WITH_NAME(tagName)
    return candidates.find((tag) => tag.type === tagType.id)
  }

  function GET_TYPES_FROM_TAG_IDS(tagIds: number[]): TagType[] {
    return tagIds.reduce<TagType[]>((accumulator, tagId) => {
      const type = GET_TYPE_FROM_TAG_ID(tagId)

      if (!type) return accumulator
      if (!accumulator.find((tagType) => tagType.id === type.id))
        accumulator.push(type)

      return accumulator
    }, [])
  }

  function GET_TYPE_FROM_TAG_ID(tagId: number): TagType | undefined {
    return types.value.find((tagType: TagType) =>
      tagType.tag_ids.includes(tagId),
    )
  }

  function GET_TYPE_FROM_ID(tagTypeId: number): TagType | undefined {
    return types.value.find((tagType: TagType) => tagType.id === tagTypeId)
  }

  function GET_TAG_TYPE_FROM_NAME(typeName: string): TagType | undefined {
    return types.value.find((tagType: TagType) => tagType.name === typeName)
  }

  function GET_TAGS_FROM_TYPE_NAME(
    typeName: string,
    excludeAllTag = true,
  ): Tag[] {
    const type = GET_TAG_TYPE_FROM_NAME(typeName)
    if (!type) return []

    const tags = GET_TAGS_FROM_IDS(type.tag_ids)
    if (excludeAllTag) return tags.filter((e) => !e.name.includes('_all_'))

    return tags
  }

  return {
    // state
    parents,
    tags,
    types,

    // actions
    RESET,
    SET,
    FETCH,

    // getter functions
    GET_PARENT_FROM_ID,
    GET_PARENT_BY_IDS,
    GET_TAGS_FROM_PARENT_ID,
    FIND_PARENTS_WITH_NAME,
    GET_PARENTS_BY_TAG_IDS,
    GET_TAG_FROM_ID,
    GET_TAGS_FROM_IDS,
    GET_TAGS_WITH_NAME,
    GET_TAG_BY_NAME_FROM_TYPE,
    GET_TYPES_FROM_TAG_IDS,
    GET_TYPE_FROM_TAG_ID,
    GET_TYPE_FROM_ID,
    GET_TAG_TYPE_FROM_NAME,
    GET_TAGS_FROM_TYPE_NAME,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTagStore, import.meta.hot))
