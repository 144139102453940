import { acceptHMRUpdate, defineStore } from 'pinia'

export type MiscBandSignupReferralState = Pick<
  ReturnType<typeof useMiscBandSignupReferralStore>,
  'code'
>

export const useMiscBandSignupReferralStore = defineStore(
  'miscBandSignupReferral',
  () => {
    const code = ref('')

    function SET_CODE(newCode: string) {
      code.value = newCode
    }

    function RESET() {
      code.value = ''
    }

    return {
      // state
      code,

      // actions
      SET_CODE,
      RESET,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscBandSignupReferralStore, import.meta.hot),
  )
}
