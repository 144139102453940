/* eslint-disable camelcase */
import isEqual from 'lodash.isequal'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftFlowStore } from './draftFlow'
import { useDraftPromoStore } from './draftPromo'
import { useDraftTrackStore } from './draftTrack'
import { useInfluencersStore } from './influencers'
import { useMiscSendtrackFiltersStore } from './miscSendtrackFilters'
import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import {
  computePercentPromotionOnCost,
  computePercentPromotionToRemoveOnCost,
} from '~/helpers/promos'

import {
  provideAutoApplyBestPromo,
  provideValidatePromoCode,
} from '~/api-core/Draft/DraftPromo'
import {
  provideAddInfluencersToDraft,
  provideRemoveInfluencersFromDraft,
} from '~/api-core/Draft/PatchInfluencers'
import { provideGetCurrentDraftInfluencers } from '~/api-core/Submissions/CurrentDraft'

import type { DraftPromoState } from './draftPromo'
import type { DraftTrackState } from './draftTrack'
import type TagType from '~/entities/tagType'
import type { DRAFT_STEP } from '~/enums/DraftStep'
import type CampaignGoal from '~/types/campaignGoal'
import type {
  APIDraftResponse,
  DraftPricingInfo,
  DraftStepStatuses,
} from '~/types/draft'
import type { StatsV3Influencer } from '~/types/influencer'
import type { ProgressivePromo } from '~/types/product'
import type { Track } from '~/types/track'

type Messages = Record<number, string | undefined | null>

export type IDraftState = {
  id: number
  band: number
  track: number
  info: null | string
  is_boosted_track: boolean
  messages: Messages
  influencers: number[]
  influencers_count: number
  draft_cost: number
  boosted_cost: number
  tags: number[]
  goals: CampaignGoal[]
  step_status: DraftStepStatuses[]
}

type DraftStateKey = keyof IDraftState

export interface DraftState extends Omit<IDraftState, 'track'> {
  pricing: DraftPricingInfo
  promo: DraftPromoState
  track: DraftTrackState
}

export const useDraftStore = defineStore('draft', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const draftFlowStore = useDraftFlowStore($pinia)
  const draftPromoStore = useDraftPromoStore($pinia)
  const draftTrackStore = useDraftTrackStore($pinia)
  const influencersStore = useInfluencersStore($pinia)
  const miscDraftProgressivePromosStore =
    useMiscDraftProgressivePromosStore($pinia)
  const miscDraftStore = useMiscDraftStore($pinia)
  const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore($pinia)
  const tagStore = useTagStore($pinia)
  const userBandStore = useUserBandStore($pinia)
  const userStore = useUserStore($pinia)

  // state
  const id = ref(0)
  const band = ref(0)
  const track = ref(0)
  const info = ref<string | null>(null)
  const is_boosted_track = ref(false)
  const messages = ref<Messages>({})
  const influencers = ref<number[]>([])
  /**
   * The count sent by the backend is always accurate, while the influencers array will be filled on the go.
   */
  const influencers_count = ref(0)
  const draft_cost = ref(0)
  const boosted_cost = ref(0)
  const tags = ref<number[]>([])
  /**
   * @deprecated - use `tags` instead and remove this property when refactoring draft.
   */
  const goals = ref<CampaignGoal[]>([])
  const step_status = ref<DraftStepStatuses[]>([])

  // getters
  const $state = computed<IDraftState>(() => ({
    id: id.value,
    band: band.value,
    track: track.value,
    info: info.value,
    is_boosted_track: is_boosted_track.value,
    messages: messages.value,
    influencers: influencers.value,
    influencers_count: influencers_count.value,
    draft_cost: draft_cost.value,
    boosted_cost: boosted_cost.value,
    tags: tags.value,
    goals: goals.value,
    step_status: step_status.value,
  }))

  const HAS_DRAFT = computed(() => id.value > 0)

  const HAS_PRESELECTED_INFLUENCERS = computed(
    () => !id.value && influencers.value.length > 0,
  )

  const INFLUENCERS = computed(() => influencers.value)

  const INF_ID_IS_IN = computed(
    () => (id: number) => influencers.value.includes(id),
  )

  const COST = computed(() => draft_cost.value)

  const MESSAGES = computed(() => {
    return influencers.value.reduce((accumulator, element) => {
      accumulator[element] = messages.value[element]?.length
        ? messages.value[element]
        : null
      return accumulator
    }, {} as Messages)
  })

  const PATCH_DATA = computed<
    Partial<IDraftState & { promo: number | null; track: number }>
  >(() => {
    return {
      id: id.value,
      band: band.value,
      info: info.value,
      influencers: influencers.value.length ? influencers.value : undefined,
      influencers_count: influencers_count.value,
      draft_cost: draft_cost.value,
      tags: tags.value,
      goals: goals.value,
      messages: Object.keys(MESSAGES.value).length ? MESSAGES.value : undefined,
      step_status: step_status.value,
      is_boosted_track: is_boosted_track.value,
      promo: draftPromoStore.id || null,
      track: draftTrackStore.id,
    }
  })

  const SELECTED_TAGS = computed<Record<TagType['name'], number[]>>(() => {
    return tagStore.types.reduce(
      (accumulator, tagType) => {
        const tagIdArray = tags.value.filter((selectedTagId) =>
          tagType.tag_ids.includes(selectedTagId),
        )

        if (tagIdArray.length) accumulator[tagType.name] = tagIdArray

        return accumulator
      },
      {} as Record<TagType['name'], number[]>,
    )
  })

  const MISSING_INFLUENCERS = computed(() => {
    return influencers.value.filter((influencerId) => {
      return !influencersStore.ID_EXISTS(influencerId)
    })
  })

  // util function
  function getStateRef(key: DraftStateKey) {
    switch (key) {
      case 'id':
        return id
      case 'band':
        return band
      case 'track':
        return track
      case 'info':
        return info
      case 'is_boosted_track':
        return is_boosted_track
      case 'messages':
        return messages
      case 'influencers':
        return influencers
      case 'influencers_count':
        return influencers_count
      case 'draft_cost':
        return draft_cost
      case 'boosted_cost':
        return boosted_cost
      case 'tags':
        return tags
      case 'goals':
        return goals
      case 'step_status':
        return step_status
      default:
    }
  }

  // actions
  function SET(patch: Partial<Record<keyof DraftState, any>>) {
    Object.keys(patch).forEach((key) => {
      const stateRef = getStateRef(key as DraftStateKey)

      if (key === 'promo') draftPromoStore.SET(patch.promo)
      if (stateRef === undefined) return

      if (key === 'track' && typeof patch.track === 'number')
        stateRef.value = patch.track
      else if (key === 'step_status') stateRef.value = patch.step_status || []
      else stateRef.value = patch[key as DraftStateKey]
    })

    // take care of step state properties
    draftFlowStore.SET_DRAFT_ID(id.value)
    draftFlowStore.UPDATE_STEP_STATE_PROPERTIES(step_status.value)
  }

  function SET_ID(newId: number) {
    id.value = newId
  }

  function SET_COST(cost: number) {
    draft_cost.value = cost
  }

  async function MARK_STEP_TO_COMPLETE(step: DRAFT_STEP) {
    const copy = [...step_status.value]

    step_status.value = step_status.value.filter((status) => status !== step)
    draftFlowStore.UPDATE_STEP_STATE_PROPERTIES(step_status.value)

    // if needed, update draft on server
    if (isEqual(copy, step_status.value)) return
    await UPDATE()
  }

  async function MARK_STEP_DONE(step: DRAFT_STEP) {
    const copy = [...step_status.value]

    // clean old values that no longer exist OR are not in the tested steps
    step_status.value = step_status.value.filter((status) =>
      draftFlowStore.steps.includes(status as DRAFT_STEP),
    )
    // set new status
    step_status.value = [...new Set([...step_status.value, step])]
    draftFlowStore.UPDATE_STEP_STATE_PROPERTIES(step_status.value)

    // if needed, update draft on server
    if (isEqual(copy, step_status.value)) return
    await UPDATE()
  }

  function ADD_INFLUENCER_ARRAY(influencerIdArray: number[]) {
    influencers.value.push(
      ...influencerIdArray.filter((e) => !influencers.value.includes(e)),
    )
    influencers_count.value = influencers.value.length
  }

  function SET_INFLUENCER_ARRAY(influencerIdArray: number[]) {
    influencers.value = influencerIdArray
    influencers_count.value = influencers.value.length
  }

  function REMOVE_INFLUENCER_ARRAY(influencerIdArray: number[]) {
    influencers.value = influencers.value.filter((e) => {
      return !influencerIdArray.includes(e)
    })
    influencers_count.value = influencers.value.length
  }

  function RESET() {
    if (import.meta.client) {
      window.localStorage.removeItem(
        `has-seen-genre-filters-for-draft-${id.value}`,
      )
    }
    if (!id.value) SAVE_DRAFT_TO_LOCAL_STORAGE()

    id.value = 0
    band.value = 0
    track.value = 0
    info.value = null
    is_boosted_track.value = false
    messages.value = {}
    influencers.value = []
    influencers_count.value = 0
    draft_cost.value = 0
    boosted_cost.value = 0
    tags.value = []
    goals.value = []
    step_status.value = []

    draftTrackStore.RESET()
    draftFlowStore.RESET()
    miscSendtrackFiltersStore.SET_SCROLL_POS(0)
  }

  function SET_MESSAGES(messages: Messages): Promise<Messages> {
    return coreFetch
      .$post<{ messages: Messages }>(`/submission/draft/${id.value}/message/`, {
        messages,
      })
      .then(({ messages }) => {
        SET({ messages })
        return messages
      })
      .catch(() => {
        return {}
      })
  }

  /**
   * Renamed "DISPATCH_SET" because this used to be an "action" also called "SET"
   * in the old vuex store.
   *
   * @param draft - the draft
   * @returns Draft.
   */
  function DISPATCH_SET(draft: APIDraftResponse) {
    SET(draft)
    CHECK_FOR_PROGRESSIVE_PROMO()

    return draft
  }

  function FETCH_BY_ID(id: number): Promise<DraftState> {
    if (!id) throw new Error(`${id} is not truthy`)

    return coreFetch.$get<DraftState>(`/submission/draft/${id}/`)
  }

  async function GET_CURRENT(config?: {
    clean_hidden_influencers: boolean
  }): Promise<APIDraftResponse | IDraftState> {
    const { clean_hidden_influencers: cleanHiddenInfluencers } = config ?? {}

    if (id.value && !cleanHiddenInfluencers) return $state.value

    const url = `/submission/draft/current/${
      cleanHiddenInfluencers === true ? '?clean_hidden_influencers=true' : ''
    }`

    try {
      const draft = await coreFetch.$get<APIDraftResponse>(url)
      if (draft.is_boosted_track === null) draft.is_boosted_track = false

      DISPATCH_SET(draft)

      if (!draft.track) draftTrackStore.RESET()

      return draft
    } catch (_) {
      return $state.value
    }
  }

  async function RETRIEVE_DRAFT_FROM_LOCAL_STORAGE() {
    if (!import.meta.client) return

    if (
      HAS_DRAFT.value ||
      HAS_PRESELECTED_INFLUENCERS.value ||
      !userStore.IS_BAND ||
      userBandStore.id === 0
    )
      return

    const bandId = userBandStore.id
    const localeStorage = window.localStorage
    if (!localeStorage) return

    const storedInfluencers = JSON.parse(
      localeStorage.getItem(`draft-${bandId}-influencers`) || '[]',
    )
    await influencersStore.FETCH_SET(storedInfluencers)

    SET_INFLUENCER_ARRAY(storedInfluencers)
    UPDATE_COST()
  }

  function SAVE_DRAFT_TO_LOCAL_STORAGE() {
    if (!import.meta.client) return

    const bandId = userBandStore.id
    const localeStorage = window.localStorage
    if (!localeStorage || !bandId || !HAS_PRESELECTED_INFLUENCERS.value) return

    localeStorage.setItem(
      `draft-${bandId}-influencers`,
      JSON.stringify(influencers.value),
    )
  }

  function EMPTY_DRAFT_LOCAL_STORAGE() {
    if (!import.meta.client) return

    const bandId = userBandStore.id
    const localeStorage = window.localStorage
    if (!localeStorage) return

    localeStorage.removeItem(`draft-${bandId}-influencers`)
  }

  /**
   * Fills the influencers array based on the influencers_count value.
   */
  async function GET_CURRENT_DRAFT_INFLUENCERS({
    fetchAll = false,
  }: { fetchAll?: boolean } = {}) {
    if (influencers.value.length === influencers_count.value)
      return $state.value

    const getCurrentDraftInfluencers =
      provideGetCurrentDraftInfluencers(coreFetch)

    try {
      const { next, results, total } = await getCurrentDraftInfluencers(
        id.value,
      )

      const infs = new Set([...influencers.value, ...results])

      SET({ influencers: [...infs] })

      if (next && fetchAll) {
        const PAGINATION_MAX_LIMIT = 100
        const totalRequests = Math.ceil(total / PAGINATION_MAX_LIMIT)

        const requests = []
        let page = Math.ceil(influencers.value.length / PAGINATION_MAX_LIMIT)
        for (page; page < totalRequests; page++) {
          requests.push(
            getCurrentDraftInfluencers(id.value, page * PAGINATION_MAX_LIMIT),
          )
        }

        const allResults = await Promise.all(requests)
        let allInfs = [] as number[]

        allResults.forEach(({ results: currentResults }) => {
          allInfs = [...new Set([...allInfs, ...currentResults])]
        })

        SET({
          influencers: [...new Set([...influencers.value, ...allInfs])],
          influencers_count: total,
        })
      }
    } catch (_) {
      return $state.value
    }
  }

  function ADD_INF(bundle: number[] | number): Promise<number[]> {
    if (typeof bundle === 'number') bundle = [bundle]

    if (!bundle.length || userStore.IS_INCOMPLETE_USER || userStore.IS_INF)
      return Promise.resolve([])

    if (!id.value) {
      ADD_INFLUENCER_ARRAY(bundle)
      UPDATE_COST()
      return Promise.resolve(bundle)
    }

    const addInfluencersToDraft = provideAddInfluencersToDraft(coreFetch)
    return addInfluencersToDraft(id.value, bundle).then(
      ({ failed_influencers: failedInfluencers }) => {
        const influencers = bundle.filter((i) => !failedInfluencers.includes(i))
        REMOVE_INFLUENCER_ARRAY(failedInfluencers)
        ADD_INFLUENCER_ARRAY(influencers)
        UPDATE_COST()
        return influencers
      },
    )
  }

  function REMOVE_INF(bundle: number | number[]): Promise<void> {
    if (typeof bundle === 'number') bundle = [bundle] as number[]

    if (!bundle.length) return Promise.resolve()

    if (!id.value) {
      REMOVE_INFLUENCER_ARRAY(bundle)
      UPDATE_COST()
      return Promise.resolve()
    }

    const removeInfluencersFromDraft =
      provideRemoveInfluencersFromDraft(coreFetch)
    return removeInfluencersFromDraft(id.value, bundle)
      .then(() => {
        REMOVE_INFLUENCER_ARRAY(bundle)
        UPDATE_COST()
      })
      .catch(() => {})
  }

  function SET_CURRENT(draftId: number): Promise<APIDraftResponse> {
    return coreFetch
      .$post<APIDraftResponse>('/submission/draft/current/', {
        current_draft: draftId,
      })
      .then((draft) => {
        DISPATCH_SET(draft)

        if (!draft.track) draftTrackStore.RESET()

        return draft
      })
      .catch(() => {
        return Promise.reject(new Error('Failed to set current draft'))
      })
  }

  async function CREATE(): Promise<APIDraftResponse | undefined> {
    let response: APIDraftResponse

    try {
      response = await coreFetch.$post<APIDraftResponse>('/submission/draft/', {
        band: userBandStore.id,
        influencers: influencers.value,
      } as { band: number; influencers: number[] })
      await SET_CURRENT(response.id)
      EMPTY_DRAFT_LOCAL_STORAGE()
      return response
    } catch (err) {}
  }

  async function UNBIND_TRACK() {
    await UPDATE_SERVER({
      patch: {
        track: null,
      },
    })
  }

  async function BIND_TRACK({
    trackId,
    previousTrackId,
  }: {
    trackId: number
    previousTrackId?: number
  }): Promise<void> {
    const oldTrackId = previousTrackId || draftTrackStore.id || 0

    await draftTrackStore.FETCH(trackId)
    const response = await UPDATE()

    /* Set the pitch to the new track's pitch.
     *
     * Explanation:
     *   - Both draft & track have the "pitch"
     *   - In Draft it's the "info" property
     *   - In Track it's also the "info" property, but it doesn't get set until after you send a campaign.
     *
     * Every time the track switches (whether the draft pitch is blank or not),
     * replace it with whatever the track pitch is (blank or not)
     */
    if (response && oldTrackId !== trackId) {
      info.value = draftTrackStore.info || ''
      UPDATE()
    }
  }

  async function UPDATE_SERVER({
    patch,
    draftId,
    track,
    withoutSetDraft,
  }: {
    patch?: Partial<Record<keyof DraftState, any>>
    draftId?: number
    track?: Partial<Track>
    withoutSetDraft?: boolean
  } = {}) {
    // Test if promo code is still valid, otherwise reset it before draft update
    if (draftPromoStore?.id) {
      try {
        const updatedDraft = await coreFetch.$patch<APIDraftResponse>(
          `/submission/draft/${draftId || id.value}/`,
          {
            ...(patch ?? {}),
            // TODO: check this promo value - it looks like it should be further nested as { code: draftPromoStore.id }
            promo: draftPromoStore.id,
          },
        )

        SET(updatedDraft)
        return
      } catch (err) {
        await draftPromoStore.RESET()
        if (patch?.promo) patch.promo = null
      }
    }

    // TODO: Check if product wants to be able to unbind the track lol
    const shouldUnbindTrack =
      patch?.track !== undefined && patch?.track === null

    const resp = await coreFetch.$patch<APIDraftResponse>(
      `/submission/draft/${draftId || id.value}/`,
      {
        ...(patch ?? {}),
        track: shouldUnbindTrack ? null : track?.id || draftTrackStore.id,
      },
    )

    if (!withoutSetDraft) {
      if (!resp.track) draftTrackStore.RESET()

      return DISPATCH_SET(resp)
    }
  }

  function UPDATE(): Promise<DraftState | APIDraftResponse | undefined> {
    return UPDATE_SERVER({ patch: { ...PATCH_DATA.value } })
  }

  function DELETE(id: number): Promise<boolean> {
    return coreFetch.$delete(`/submission/draft/${id || id}/`).then(() => {
      if (import.meta.client)
        window.localStorage.removeItem(`has-seen-genre-filters-for-draft-${id}`)

      return true
    })
  }

  async function FETCH_MISSING_INFLUENCERS() {
    if (!MISSING_INFLUENCERS.value.length) return true

    await influencersStore.FETCH_SET(MISSING_INFLUENCERS.value)
    return true
  }

  async function RESET_CURRENT() {
    if (!id.value) SAVE_DRAFT_TO_LOCAL_STORAGE()
    else await coreFetch.$delete('/submission/draft/current/').catch(() => true)
    RESET()
    return true
  }

  async function VALIDATE_PROMO_CODE(code: string, onlyApplyIfBetter = false) {
    const validatePromoCode = provideValidatePromoCode(coreFetch)
    const { promo } = await validatePromoCode(code, id.value, onlyApplyIfBetter)

    draftPromoStore.SET(promo)
    return promo.code === code
  }

  function FETCH_UPDATED_INFLUENCERS(): Promise<{
    influencersNoLongerAvailable: number[]
    influencersWithNewCost: number[]
  }> {
    return new Promise((resolve, reject) => {
      coreFetch
        .$get<{
          [id: string]: {
            visible: boolean
            cost: string
          }
        }>(`/submission/influencers_status/?draft_id=${id.value}`)
        .then((res) => {
          const localInfluencers: StatsV3Influencer[] =
            influencersStore.GET_BY_IDS_SORTED(influencers.value)
          const influencersNoLongerAvailable = localInfluencers
            .filter((influencer: StatsV3Influencer) => {
              return (
                res[influencer?.id] === undefined ||
                res?.[influencer?.id]?.visible === false
              )
            })
            .map((e: StatsV3Influencer) => e.id)
          const influencersWithNewCost = localInfluencers
            .filter((influencer: StatsV3Influencer) => {
              return (
                res[influencer?.id] !== undefined &&
                parseInt(influencer?.cost) !==
                  parseInt(res?.[influencer?.id]?.cost)
              )
            })
            .map((e: StatsV3Influencer) => e.id)
          resolve({
            influencersNoLongerAvailable,
            influencersWithNewCost,
          })
        })
        .catch(reject)
    })
  }

  async function CHECK_FOR_PROGRESSIVE_PROMO(): Promise<ProgressivePromo | null> {
    if (id.value === 0 || !miscDraftProgressivePromosStore.IS_ENABLED)
      return null

    const eligibleProgressivePromo =
      miscDraftProgressivePromosStore.PROGRESSIVE_PROMO_FROM_INF_COUNT(
        influencers_count.value,
      )

    if (!eligibleProgressivePromo && draftPromoStore.IS_PROGRESSIVE_PROMO) {
      // edge case: when a progressive promo was applied but user removed curators from draft and no progressive promo is eligible anymore
      await RESET()
      return null
    } else if (!eligibleProgressivePromo) {
      return null
    }
    return eligibleProgressivePromo || null
  }

  async function CHECK_FOR_AUTO_APPLY_PROMO() {
    if (id.value === 0) return null

    const autoApplyBestPromo = provideAutoApplyBestPromo(coreFetch)
    const draftAutoAppliedPromo = await autoApplyBestPromo(id.value)
    if (draftAutoAppliedPromo && draftAutoAppliedPromo.promo) {
      SET({ promo: draftAutoAppliedPromo.promo })
      miscDraftStore.SET_USES_SOUND_CLOUD_NEXT_PROMO_CODE(
        draftAutoAppliedPromo.promo.rule.includes('soundcloud_nextpro is true'),
      )
    }
  }

  const IS_VALID_PERCENT = computed(() => {
    // TODO: check for percentages in upsells also?
    if (id.value > 0 && draftPromoStore.actions.campaign.is_percentage) {
      return (
        COST.value >= Math.round(100 / draftPromoStore.actions.campaign.value)
      )
    } else {
      return true
    }
  })

  const TO_REMOVE_FROM_COST = computed<{
    campaign: number
    hype: number
  }>(() => {
    const discounts = {
      campaign: 0,
      hype: 0,
    }

    for (const action in draftPromoStore.actions) {
      const promoAction = action as keyof DraftPromoState['actions']
      const baseCost: number =
        action === 'campaign' ? COST.value : boosted_cost.value
      const product = draftPromoStore.actions[promoAction]

      if (id.value > 0 && draftPromoStore.code !== '') {
        if (product.is_percentage) {
          discounts[promoAction] = computePercentPromotionToRemoveOnCost({
            baseCost,
            value: product.value,
          })
        } else {
          discounts[promoAction] = Math.min(product.value, baseCost)
        }
      } else {
        discounts[promoAction] = 0
      }
    }

    return discounts
  })

  const CAMPAIGN_COST_AFTER_PROMO = computed<number>(() => {
    return id.value > 0
      ? COST.value - TO_REMOVE_FROM_COST.value.campaign
      : COST.value
  })

  const BOOSTED_COST_AFTER_PROMO = computed<number>(() => {
    if (!is_boosted_track.value) return boosted_cost.value

    return id.value > 0
      ? boosted_cost.value - TO_REMOVE_FROM_COST.value.hype
      : boosted_cost.value
  })

  const DRAFT_COST = computed(() => {
    return COST.value + boosted_cost.value
  })

  const DRAFT_COST_AFTER_PROMO = computed((): number => {
    return CAMPAIGN_COST_AFTER_PROMO.value + BOOSTED_COST_AFTER_PROMO.value
  })

  const DRAFT_COST_AFTER_PROGRESSIVE_PROMO = computed(() => {
    const baseCost = COST.value
    const promoToApply =
      miscDraftProgressivePromosStore.PROGRESSIVE_PROMO_FROM_INF_COUNT(
        influencers_count.value,
      )
    if (promoToApply) {
      return computePercentPromotionOnCost({
        baseCost,
        value: promoToApply.actions.campaign.value,
      })
    } else {
      return baseCost
    }
  })

  const NEXT_PROGRESSIVE_PROMO_FROM_INF_COUNT =
    computed<ProgressivePromo | null>(() => {
      const influencerCount = influencers_count.value
      const promos = miscDraftProgressivePromosStore.list.length
        ? miscDraftProgressivePromosStore.list
        : null

      if (!promos) return null

      const eligiblePromos = promos.filter(
        (promo) =>
          influencerCount < promo.progressive_parameters.min_influencers,
      )
      const lowestEligiblePromo = eligiblePromos.sort(
        (a, b) => a.actions.campaign.value - b.actions.campaign.value,
      )?.[0]
      return lowestEligiblePromo ?? null
    })

  function UPDATE_COST() {
    const updatedCost = influencers.value.reduce((accumulator, element) => {
      const cost = influencersStore.GET_BY_ID(element)?.cost ?? 0

      if (cost) return accumulator + Number(cost)
      else return accumulator
    }, 0)

    SET_COST(updatedCost)
  }

  return {
    // state
    id,
    band,
    track,
    info,
    is_boosted_track,
    messages,
    influencers,
    influencers_count,
    draft_cost,
    boosted_cost,
    tags,
    /**
     * @deprecated - use `tags` instead and remove this property when refactoring draft.
     */
    goals,
    step_status,

    // getters
    BOOSTED_COST_AFTER_PROMO,
    CAMPAIGN_COST_AFTER_PROMO,
    COST,
    DRAFT_COST_AFTER_PROGRESSIVE_PROMO,
    DRAFT_COST_AFTER_PROMO,
    DRAFT_COST,
    HAS_DRAFT,
    HAS_PRESELECTED_INFLUENCERS,
    INF_ID_IS_IN,
    INFLUENCERS,
    IS_VALID_PERCENT,
    MESSAGES,
    MISSING_INFLUENCERS,
    NEXT_PROGRESSIVE_PROMO_FROM_INF_COUNT,
    PATCH_DATA,
    SELECTED_TAGS,
    $state,
    TO_REMOVE_FROM_COST,

    // actions
    ADD_INF,
    ADD_INFLUENCER_ARRAY,
    BIND_TRACK,
    CREATE,
    CHECK_FOR_PROGRESSIVE_PROMO,
    CHECK_FOR_AUTO_APPLY_PROMO,
    DELETE,
    DISPATCH_SET,
    RETRIEVE_DRAFT_FROM_LOCAL_STORAGE,
    FETCH_BY_ID,
    FETCH_MISSING_INFLUENCERS,
    FETCH_UPDATED_INFLUENCERS,
    GET_CURRENT,
    GET_CURRENT_DRAFT_INFLUENCERS,
    MARK_STEP_DONE,
    MARK_STEP_TO_COMPLETE,
    REMOVE_INF,
    REMOVE_INFLUENCER_ARRAY,
    RESET,
    RESET_CURRENT,
    SAVE_DRAFT_TO_LOCAL_STORAGE,
    SET,
    SET_COST,
    SET_CURRENT,
    SET_ID,
    SET_INFLUENCER_ARRAY,
    SET_MESSAGES,
    UNBIND_TRACK,
    UPDATE,
    UPDATE_COST,
    UPDATE_SERVER,
    VALIDATE_PROMO_CODE,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftStore, import.meta.hot))
