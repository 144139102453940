import { acceptHMRUpdate, defineStore } from 'pinia'

import { useUserStore } from './user'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { provideGetCurrentUserFamily } from '~/api-core/Family/index'

import type { Locale } from '~/types/locale'
import type { UserAccount } from '~/types/userAccount'

export interface RootState {
  accounts: UserAccount[]
  locales: Locale[]
}

export const useRootStore = defineStore('root', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const userStore = useUserStore($pinia)

  const accounts = ref<UserAccount[]>([])
  const locales = ref<Locale[]>(['en', 'fr'])

  function REMOVE_ACCOUNT_BY_ID(accountId: number) {
    const index = accounts.value.findIndex((e) => e.pk === accountId)
    if (index > 0) accounts.value.splice(index, 1)
  }

  function SET_ACCOUNTS(newAccounts: UserAccount[]) {
    accounts.value = newAccounts
  }

  async function ACCOUNTS_LOAD() {
    if (!userStore.IS_LOGGED_IN) return []

    const getFamilyAccounts = provideGetCurrentUserFamily(coreFetch)
    const accounts = await getFamilyAccounts().catch(() => [])

    SET_ACCOUNTS(accounts)

    return accounts
  }

  function ADD_NEW_ACCOUNT(account: UserAccount) {
    if (accounts.value.find((e) => e.pk === account.pk)) return

    SET_ACCOUNTS([...accounts.value, account])
  }

  function USER_LOAD() {
    return userStore.LOAD()
  }

  return {
    accounts,
    locales,

    REMOVE_ACCOUNT_BY_ID,
    SET_ACCOUNTS,
    ACCOUNTS_LOAD,
    ADD_NEW_ACCOUNT,
    USER_LOAD,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot))
