import type { CoreFetch } from '~/types/CoreFetch'
import type { GrowthBookApiResponse } from '~/types/GrowthBookFeatureLibrary'

const GROWTHBOOK_URL = '/distantapi/growthbook/'

export function provideGrowthBookGetFeatureSet(coreFetch: CoreFetch) {
  return (): Promise<GrowthBookApiResponse> => {
    return coreFetch.$get<GrowthBookApiResponse>(GROWTHBOOK_URL)
  }
}
