export default defineNuxtPlugin(() => {
  const cookie = useCookie('__datocms_preview_data')

  return {
    provide: {
      enablePreviewUrl: () => '/api/enable-preview',
      disablePreviewUrl: () => '/api/disable-preview',

      isPreviewEnabled: !!cookie.value,
    },
  }
})
