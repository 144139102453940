import { useMiscBandSignupStore } from '~/stores/miscBandSignup'
import { useUserStore } from '~/stores/user'

import { AvailableUserKinds } from '~/types/UserKind'

export function useSyncWidgetContext() {
  const { $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const miscBandSignupStore = useMiscBandSignupStore($pinia)
  const storedContextJsonCookie = useCookie(
    `widgetContextJson${userStore.id}`,
    {
      maxAge: 7 * 24 * 3600, // 1 week in seconds
    },
  )

  function syncWidgetContext() {
    if (!userStore.id) return

    // No serialized versions but local store version
    if (miscBandSignupStore.widgetContext !== null) {
      storedContextJsonCookie.value = JSON.stringify({
        ...miscBandSignupStore.widgetContext,
      })
    } else if (storedContextJsonCookie && storedContextJsonCookie.value) {
      try {
        const candidate = JSON.parse(storedContextJsonCookie.value)

        if (checkWidgetContext(candidate))
          miscBandSignupStore.SET_WIDGET_CONTEXT(candidate)
      } catch (_err) {
        /**
         * Invalid JSON, this only happens when user
         * modifies the entry in the debugger.
         *
         * We'll just purge it from the registry.
         */
        storedContextJsonCookie.value = null
      }
    }
  }

  function checkWidgetContext(
    candidate: any,
  ): candidate is MiscBandSignupWidgetContext {
    if (typeof candidate === 'object' && candidate !== null) {
      return (
        typeof candidate.entityId === 'number' &&
        Object.values(AvailableUserKinds).includes(candidate.userKind)
      )
    } else {
      return false
    }
  }

  return {
    syncWidgetContext,
  } as const
}
