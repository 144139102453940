import { acceptHMRUpdate, defineStore } from 'pinia'

import { useUserStore } from './user'

import type { LegalData } from '~/types/legalData'

const initialState = () => {
  return {
    birth_date: null,
    city: '',
    country: 0,
    entity: '',
    first_name: '',
    id: 0,
    is_liable: false,
    last_name: '',
    postal_code: '',
    street: '',
    user: 0,
    vat_number: '',
  } satisfies LegalData
}

const state = initialState

export type PayinBillingInfosLegalDataState = ReturnType<typeof state>

export const usePayinBillingInfosLegalDataStore = defineStore(
  'payinBillingInfosLegalData',
  () => {
    const { $pinia } = useNuxtApp()
    const userStore = useUserStore($pinia)
    const { coreFetch } = useProvideCoreFetch()

    const id = ref(0)
    const birth_date = ref(null)
    const city = ref('')
    const country = ref(0)
    const entity = ref('')
    const first_name = ref('')
    const last_name = ref('')
    const is_liable = ref(false)
    const postal_code = ref('')
    const street = ref('')
    const user = ref(0)
    const vat_number = ref('')

    //getters

    const CAN_PATCH = computed(() => {
      return id.value > 0
    })

    //actions

    function getStateRef(key: keyof PayinBillingInfosLegalDataState) {
      switch (key) {
        case 'id':
          return id
        case 'birth_date':
          return birth_date
        case 'city':
          return city
        case 'country':
          return country
        case 'entity':
          return entity
        case 'first_name':
          return first_name
        case 'last_name':
          return last_name
        case 'is_liable':
          return is_liable
        case 'postal_code':
          return postal_code
        case 'street':
          return street
        case 'user':
          return user
        case 'vat_number':
          return vat_number
      }
    }

    function SET(data: Record<keyof PayinBillingInfosLegalDataState, any>) {
      const patchKeys = Object.keys(
        data,
      ) as (keyof PayinBillingInfosLegalDataState)[]

      patchKeys.forEach((key) => {
        const ref = getStateRef(key)
        if (ref) ref.value = data[key]
      })
    }

    function RESET() {
      Object.keys(initialState()).forEach((key) => {
        const ref = getStateRef(key as keyof PayinBillingInfosLegalDataState)
        if (ref) {
          ref.value =
            initialState()[key as keyof PayinBillingInfosLegalDataState]
        }
      })
    }

    async function FETCH() {
      const resp = await coreFetch
        .$get<[PayinBillingInfosLegalDataState]>('/wallet/legaldata/')
        .catch(() => {
          RESET()
          return [initialState()]
        })

      if (!resp[0]) {
        RESET()
        return initialState()
      }

      SET({
        ...resp[0],
      })
      return resp[0]
    }

    async function UPDATE_ON_SERVER(
      newLegalData: Record<keyof PayinBillingInfosLegalDataState, any>,
    ) {
      const sendFn = CAN_PATCH.value
        ? coreFetch.$patch<PayinBillingInfosLegalDataState>
        : coreFetch.$post<PayinBillingInfosLegalDataState>
      let url = '/wallet/legaldata/'

      if (CAN_PATCH.value) url += `${id.value}/`

      const resp = await sendFn(url, {
        ...newLegalData,
        id: undefined,
        user: userStore.id,
      })

      return {
        ...resp,
      }
    }

    return {
      // state
      id,
      birth_date,
      city,
      country,
      entity,
      first_name,
      last_name,
      is_liable,
      postal_code,
      street,
      user,
      vat_number,
      initialState,

      // getters
      CAN_PATCH,

      // actions
      RESET,
      FETCH,
      SET,
      UPDATE_ON_SERVER,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinBillingInfosLegalDataStore, import.meta.hot),
  )
}
