import { acceptHMRUpdate, defineStore } from 'pinia'

import type Pack from '~/types/pack'

type PackApiResponse = Omit<Pack, 'cost' | 'groovies'> & {
  cost: string
  groovies: string
}

export type PayinPacksState = Pick<
  ReturnType<typeof usePayinPacksStore>,
  'raw_available' | 'selected'
>

export const usePayinPacksStore = defineStore('payinPacks', () => {
  const { coreFetch } = useProvideCoreFetch()

  const raw_available = ref([] as Pack[])
  const selected = ref(0)

  // getters

  const SELECTED_PACK_OBJ = computed<Pack | undefined>(() => {
    if (IS_SELECTED.value as boolean) {
      return raw_available.value.find((elem) => {
        return elem.id === selected.value
      })
    }
  })

  const SELECTED_PERCENT = computed<number | undefined>(() => {
    if (IS_SELECTED.value as boolean) {
      const pack = SELECTED_PACK_OBJ.value as Pack
      return Math.round((1 - pack.cost / pack.groovies) * 100)
    } else {
      return 0
    }
  })

  const IS_SELECTED = computed(() => {
    return selected.value > 0
  })

  // actions

  function SELECT_BY_ID(packId: number) {
    selected.value = packId
  }

  function SET_AVAILABLES(newAvailable: Pack[]) {
    raw_available.value.splice(0, raw_available.value.length)

    newAvailable.sort((a, b) => {
      return b.cost - a.cost
    })
    for (let i = newAvailable.length - 1; i >= 0; i--)
      raw_available.value.push(newAvailable[i])
  }

  async function FETCH() {
    const packs = await coreFetch.$get<PackApiResponse[]>('/wallet/pack/')

    SET_AVAILABLES(
      packs.map((response) => {
        return {
          ...response,
          cost: Number(response.cost),
          groovies: Number(response.groovies),
        } as Pack
      }),
    )
    return packs
  }

  return {
    // state
    raw_available,
    selected,

    // getters
    SELECTED_PACK_OBJ,
    SELECTED_PERCENT,
    IS_SELECTED,

    // actions
    SELECT_BY_ID,
    SET_AVAILABLES,
    FETCH,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePayinPacksStore, import.meta.hot))
